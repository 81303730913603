<template>
    <div class="header" :class="{'-fixed':routeName === 'index','-bg':addBg}">
       <div class="-logo">
            <img :src=logoPng>
       </div>
       <div class="-menu">
            <div class="-menu-item" v-for="(menu , i) in menuData" :key="i">
                <h3 :class="{'-active':routeName === menu.name}"><a v-text="menu.title" @click="linkTo(menu.link as string)"></a></h3>
                <div class="-sub-menu" v-if="menu.subTitle">
                    <h5 class="-sub-menu-item" v-for="(subMenu,o) in menu.subTitle" :key="o">
                        <a v-text="subMenu.title" @click="linkSubTo(menu.parentLink as string ,subMenu.link as string)"></a>
                    </h5>
                </div>
            </div>
       </div>
    </div>
</template>

<script lang="ts" setup>

    import logoPng from '@/images/logo.png';
    import {computed,onMounted,onUnmounted,Ref,ref} from 'vue';
    import {menuData} from './../dic/menuInfo';
    import { useRoute,useRouter } from 'vue-router';

    const route = useRoute();
    const routeName = computed(()=>{
        return route.name;
    });

    const router = useRouter();
    
    const linkTo = (link:string)=>{
        router.push(link);
    }
    const linkSubTo = (parentLink:string,link:string)=>{
        router.push(`${parentLink}/${link}`);
    }

    const scrollTop:Ref<number> = ref(window.scrollY)
    const eventA = ()=>{
        scrollTop.value = window.scrollY;
    };
    const addBg =computed(()=>{
        return route.name === 'index' && scrollTop.value > 500;
    })
    onMounted(()=>{
        window.addEventListener('scroll',eventA);
    })
    onUnmounted(()=>{
        window.removeEventListener('scroll',eventA);
    })

</script>
<style lang="scss">
    $mainColor:#e7871d;
    .header{
        height:100px;
        width:100%;
        padding:0 30px;
        display:flex;
        box-sizing: border-box;
        justify-content: space-between;
        align-items: center;
        position: absolute;
        top:0;
        left:0; 
        &.-fixed{
            // background:rgba(0,0,0,0.4);
            position:fixed;
        }
        &.-bg{
            background:rgba(0,0,0,0.7);
        }
        width:100%;
        min-width: 1200px;
        z-index: 9999;
        &:hover{
            background:rgba(0,0,0,0.7);
        }
        .-menu{
            display: flex;
            color:#fff;
            height: 100%;
            a{
                cursor: pointer;
            }
            .-menu-item{
                height:100%;
                position: relative;
                padding:0 20px;
                > h3{
                    width:100%;
                    height:100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    &:hover{
                        color:$mainColor;
                    }
                    &.-active{
                        color:$mainColor;
                    }
                }
                &:hover{
                    .-sub-menu{
                        display: block;
                    }
                }
                .-sub-menu{
                    display: none;
                    position:absolute;
                    left:0;
                    width:100%;
                    top:100%;
                    background-color: rgba(0,0,0,0.7);
                    padding:0 0 15px 0;
                    .-sub-menu-item{
                        height:36px;
                        line-height: 36px;
                        padding:0 20px;
                        // transform: translateY(-60%);
                        &:hover{
                            color:$mainColor;
                        }
                    }
                }
            }
        }
    }
</style>