interface titleInfo {
    title:string,
    name?:string,
    link?:string,
    parentLink?:string,
}
interface menuInfo extends titleInfo{
    subTitle?:titleInfo[]
}

export const aboutusMenu:menuInfo = {
    title:'关于我们',
    link:'/aboutus/main',
    parentLink:'/aboutus',
    name:'aboutus',
    subTitle:[
        {
            title:'企业概况',
            link:'main',
        },
        {
            title:'公司架构',
            link:'architecture'
        },
        {
            title:'发展历程',
            link:'history'
        },
        {
            title:'资质荣誉',
            link:'honors'
        },
        {
            title:'知识产权',
            link:'intellectual'
        },
        {
            title:'社会责任',
            link:'csr'
        }
    ]
}
export const solutionMenu:menuInfo = {
    title:'解决方案',
    link:'/solution/traffic',
    parentLink:'/solution',
    name:'solution',
    subTitle:[
        {
            title:'数字交通',
            link:'traffic'
        },
        {
            title:'数字海洋',
            link:'ocean'
        },
        {
            title:'数字低空',
            link:'altitude'
        },
        {
            title:'数字能源',
            link:'energy'
        },
        {
            title:'数字城市',
            link:'city'
        }
    ]
}
export const newsMenu:menuInfo = {
    title:'新闻中心',
    link:'/news/buiness',
    parentLink:'/news',
    name:'news',
    subTitle:[
        {
            title:'企业动态',
            link:'buiness'
        },
        {
            title:'媒体报道',
            link:'reports'
        },
        {
            title:'行业资讯',
            link:'information'
        },
    ]
}
export const newsType = {
    'buiness':3,
    'reports':4,
    'information':5
}
export const menuData:menuInfo[] = [
    {
        title:'首页',
        name:'index',
        link:'/'
    },
    {...aboutusMenu},
    {...solutionMenu},
    {
        title:'北斗科普',
        link:'/science',
        name:'science',
    },
    {
        ...newsMenu
    },
    {
        title:'企业文化',
        name:'culture',
        link:'/culture'
    },
    {
        title:'人力资源',
        name:'hr',
        link:'/hr'
    },
    {
        title:'联系我们',
        name:'contactus',
        link:'/contactus'
    }
]