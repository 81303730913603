
import { createApp } from 'vue';

// eslint-disable-next-line
//@ts-ignore
import mitt,{Emitter} from 'mitt';


import router from '@/router/index';
import {store,key} from '@/store/store';
import App from './App.vue';
const app = createApp(App);

type Events = {
    foo: string;
    bar?: number;
};
const emitter:Emitter<Events> = mitt<Events>();
app.config.globalProperties.$mitter =  emitter;

app.use(store,key)
  .use(router)
  .mount('#app')
 