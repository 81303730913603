export const solutionInfo = {
    traffic:{
        title:'数字交通',
        summary:'福信富通数字交通解决方案利用北斗卫星导航、物联网、智能分析技术、可视化技术与实际业务场景融合，运用“云-边-端”全栈式技术，基于北斗时空大数据底座，结合业务平台对车辆运输及运营前、中、后全链条全要素实现动态监管，构建安全隐患“发现-处置-跟踪-评估”体系，实现“全立体监管、全周期监控”闭环管理，为交通运输及出行管理提供北斗智能化解决方案。',
        lists:[
            {
                pic:require('@/images/solution/traffic_1.png'),
                title:'北斗 + 数字交运',
                info:'北斗+数字交运系统，依托北斗卫星定位系统，对各类城市营运车辆进行运行监测管理，并在此基础上根据不同省市、不同用户群体实现各类数据分析及个性化功能定制。系统集事前的安全预防、事中的制止及事后的救援于一体，为政府监管、企业管理及事故预防、制止、事中证据固定及事故救援提供即时可靠的一体化解决方案。',
                subLists:[
                    {
                        title:'联网联控政府监管平台',
                        pic:require('@/images/solution/traffic_1_1.jpg'),
                        info:'联⽹联控政府监管平台实现对重点运营车辆的超速、疲劳驾驶等违规行为监督管理，对各级交通管理部门、卫星定位服务商及运输企业的考核管理，为交通、安监等行政管理部门提供了有效的执法、监督和决策依据，作为全省道路运输车辆动态监管的技术平台，系统在保障道路交通运输安全生产和广大驾乘人员人身安全、提升道路交通运输管理和服务水平等方面发挥了巨大作用，社会经济效益显著。'
                    },
                    {
                        title:'主动安全防控解决方案',
                        pic:require('@/images/solution/traffic_1_2.jpg'),
                        info:'通过北斗卫星定位技术，车辆能够精准的被实时在线监控，行驶路线 一目了然，随时掌握车辆的位置，建立企业公司与车辆之间迅速、准确、有效的信息传递通道，实现对车辆的有效管理。通过大数据分析和智能报表，实现对车辆运输企业的远程监控、智能监管、安全考核，为安全生产提供大数据支持。产品严格遵循交通运输相关国家及行业标准，符合JT/T1077、JT/T1078、JT/T796、JT/T808、JT/T809、苏标系列标准，同时可提供灵活多样的部署方案，满足大、中、小型项目的各种不同环境需求'
                    
                    },
                    {
                        title:'出租⻋⽹约⻋政府监管解决方案',
                        pic:require('@/images/solution/traffic_1_3.jpg'),
                        info:'出租车、网约车应用主要面向巡游出租车/网约车行业管理部门、巡游出租车/网约车企业用户，提供一体化车辆管理数字化解决方案，减低运营成本，帮助汽车租赁企业实现车辆精细化管理。系统平台以巡游出租车行业标准为蓝本，通过对巡游出租车/网约车系列智能车载终端的感知，实现对巡游出租车辆营运管理、终端运维、违规运营、应急调度、行车安全、运营分析等行业业务应用，进一步提升巡游出租车/网约车管理效率，提升服务质量。通过数据分析筛查企业安全问题，对企业安全培训成效进行评估，协助监督企业行车安全管理工作，提升巡游出租车运营安全。'
                    },
                    {
                        title:'特种车辆监管解决方案',
                        pic:require('@/images/solution/traffic_1_4.jpg'),
                        info:'特种车辆的调度、监控、管理和控制。系统集成了北斗定位、RFID识别、视频监控等技术，通过北斗智能终端感知设备进行数据采集、挖掘、分析及处理，将人、车、物、事件、作业实况等多维度信息融合，通过数字地图+数据看板的方式直观展示，实现特种车辆运营全屏可视化和精细化管理，为车辆安全运行、运力调配、智能决策等提供支持。通过指挥调度、融合通信、视频联网、大屏展示等平台建设，有效强化城市管理监督和指挥处置能力。'
                    }
                ]
            },
            {
                pic:require('@/images/solution/traffic_2.png'),
                title:'北斗 + 数字出行',
                info:'北斗+数字出行应⽤围绕两轮电动车的安全管理展开，通过北斗+BMS模组及平台可以实现“电池风险可识别、骑行违规可监管、骑手身份可落实、行驶轨迹可查询、车辆停放可管控、运营管理可监督”。通过北斗高精度定位模组，将车辆、人员、位置、轨迹等信息汇聚到北斗时空数据底座，为运营企业车辆运营、共享出行和充换电业务提供北斗数据支持服务。',
                subLists:[
                    {
                        title:'寄递外卖交通安全监管解决方案',
                        pic:require('@/images/solution/traffic_2_1.jpg'),
                        info:'采用高精度北斗网格码技术建设覆盖主城区的北斗高精定位网络，为配送电动自行车管理提供高精度网络支持。在全市建设一定数量的北斗高精基准站，并对违规(超速、逆行、闯禁区报警)等数据，实现大数据分析，人车绑定，实时定位，轨迹回放，交通违法管理等功能。'
                    },
                    {
                        title:'共享电单⻋监管解决方案',
                        pic:require('@/images/solution/traffic_2_2.jpg'),
                        info:'共享电单⻋监管平台，通过“一网统管”，加强对共享电单车的数字化管理，实时掌握重点区域共享电单车位置、状态、数量。各相关部门和企业数据互通，实现车辆违停自动发现，立案、派单、处置、核查等，督促企业履行管理主体责任。'
                    
                    }
                ]
            }
        ]
    },
    ocean:{
        title:'数字海洋',
        summary:'福信富通智慧海洋解决方案利用北斗卫星导航、物联网、大数据分析、可视化技术与实际业务场景融合，运用“云-边-端”全栈式技术，以北斗时空大数据底座为核心数据来源，对不同海域范围内各类船只进行全要素的动态监管，构建全方位的紧急救援与安全体系，实现“海洋、人员、船只三合一的智能监管，为智慧海洋管理提供北斗智能化解决方案。',
        lists:[
            {
                pic:require('@/images/solution/ocean_1.png'),
                title:'北斗海上应急监管应用',
                info:'北斗渔船应急监管应用以北斗卫星定位技术为核心，通过在每艘渔船上安装卫星通讯和定位二合一的终端设备，实现对渔船的全面监测。如果渔船发生异常情况，系统会自动触发警报，并将相关信息传递给相关部门。渔民也可以通过卫星通讯终端和岸边的家人进行通讯，为渔民们的海上安全通讯提供了一体化的解决方案。 除了提供应急救援服务之外，北斗卫星渔船应急监管系统还能够帮助管理部门加强对渔业资源的监管和保护。例如，它可以通过实时监控渔船的位置和航向，防止非法捕捞和资源浪费，保护海洋生态环境。此外，它还可以为渔民提供更加精准的天气预报、航线规划和其他服务，帮助他们更好地开展渔业生产活动。',
                subLists:[
                    {
                        title:'北斗渔船应急监管平台及终端',
                        pic:require('@/images/solution/ocean_1_1.jpg'),
                        info:'北斗渔船应急监管平台分为渔船位置监管、渔船身份监管和渔船应急调度三部分。渔船位置是智慧渔港监管平台基于AIS、北斗等装置实现的功能。系统可以通过这些定位设备，实时确定渔船的实际位置。渔船身份监管是基于渔船电子档案和船舶号人工智能识别算法实现的功能，可以智能识别在港渔船的身份。渔船应急调度部分是基于前两部分的数据汇聚至后端算力平台，智能预判港内渔船状态是否存在异常，并在发现异常的时候自动向管理人员发送警报。实现一键智慧调度的管理功能。平台的应急指挥中心还可以结合北斗定位数据、气象数据等可对近、远海的渔船提供海上遇险、实时台风路径台风风圈等突发应急事件的技术服务，让出海作业的渔民用手机就能实时查询海洋预警预报信息。'
                    }
                ]
            },
            {
                pic:require('@/images/solution/ocean_2.png'),
                title:'北斗乡镇船舶智能动态监管应用',
                info:'北斗乡镇船舶智能动态监管系统以北斗高精度创新技术为核心，以终端、云平台、AI算法等技术相结合，融合船舶定位、分析、预警和应急服务等功能于一体，实现对船舶、人、湖泊等全要素一体化管理，保障船舶通信与数据安全，有效帮助监管部门监测船舶实时动态，实现船舶、渔民信息监管可视化、智能化，打通船舶安全管理“最后一公里”。',
                subLists:[
                    {
                        title:'北斗乡镇船舶动态监管平台及终端',
                        pic:require('@/images/solution/ocean_2_1.jpg'),
                        info:'北斗乡镇船舶动态管理平台以渔船、渔民、渔政管理部门为主体，提供智能化的渔业管理一体化解决方案。平台通过卫星定位和传感器技术来获取位置、速度和航向等关键数据，提供实时通信渠道，促进船舶之间的信息共享和协同，并运用智能分析和决策支持算法，可以根据数据生成警报、建议最佳航线、和管理船只的维护计划，在渔业管理模块，可以提供渔场信息、天气预报、渔情分析等功能的终端运用，以优化捕捞活动并提高渔业可持续性。乡镇船舶管理平台严格遵循国际海事组织（IMO）、国际电工委员会（IEC）、船级社、国家和地区法规、ISO、海洋生态保护等行业标准和技术规范，以确保船舶运营的安全性、合规性和高效性。'
                    }
                ]
            },
            {
                pic:require('@/images/solution/ocean_3.png'),
                title:'北斗+5G 海洋远距离融合通信应用',
                info:'随着经济的快速发展,远洋旅游、运输等商业活动愈发频繁，但由于远离陆地,没有移动通信信号,远洋船舶上无法使用移动通信来满足电话、短信收发和娱乐等通信业务的需求。北斗+5G 海洋远距离海洋融合通信应用是基于北斗卫星导航系统和5G 融合技术的海洋通信解决方案，构建了"卫星回传+边缘计算+网络切片"的组网模式,为海洋领域带来了前所未有的通信能力和数据传输速度，其高带宽和低延迟性能带来了实时视频传输的无限潜力，有效提升海上通信能力和安全性，为海洋经济发展提供有力支持。',
                subLists:[
                    {
                        title:'北斗+5G 远距离通讯平台',
                        pic:require('@/images/solution/ocean_3_1.jpg'),
                        info:'北斗+5G 远距离通讯平台以执法部门和监管对象为主体，以创新5G高速海洋网络和北斗定位技术为核心，结合5G物联网、云计算、大数据、等技术，为渔船作业提供远距离高速率、低延时的海洋通讯一体化解决方案。平台采用“卫星宽带+5G企业网关+全船组网+智能终端”的标准化融合方案,在为渔船作业提供优质上网体验、语音电话的同时,兼顾视频监控和网络通道切换等需求，保障出海作业时可同时满足5G信号、卫星宽带两种形式的信号输入,使得渔船在整个海域的网络需求得到保证，实现网络模式的全域覆盖。同时，平台还可以为海洋科学研究、渔业生产、海上救援等领域提供高效、可靠、安全的通信支持，赋能海洋经济高质量发展。'
                    }
                ]
            },
            {
                pic:require('@/images/solution/ocean_4.png'),
                title:'北斗反无人机系统应用',
                info:'北斗反无人机系统的通过雷达、光学、声学等探测技术，对无人机进行探测、识别和跟踪。该干扰技术主要依托电子战和物理干扰两种。电子战主要是通过电磁波干扰和定向能干扰无人机的通信系统和控制系统，使其丧失导航和定位能力，并最终迫使其降落或返回起飞点；物理干扰则包括红外干扰、声学干扰等方法，通过干扰无人机的传感器和控制系统来实现干扰的目的，我司反无人机系统主要应用于军事领域，在军事基地和战术部署区域附近部署可以用来保护这些重要目标免受敌方无人机的威胁。通过高精度的雷达和光学传感器，系统可以准确探测到无人机的存在，并及时采取干扰手段将其击毁或迫使其返航。',
                subLists:[
                    {
                        title:'北斗反无人机管控平台',
                        pic:require('@/images/solution/ocean_4_1.jpg'),
                        info:'北斗反无人机系统应用由前端硬件装备及后端软件系统组成，前端装备包含固定式无人机侦测装备、固定式无人机反制装备，基于TDOA频谱探测技术。单台装备可独立发现识别无人机。多台装备可组网实现无人机的精准定位和轨迹跟踪，实现对弹药库核心区域及其周边空域进行无人机侦测及预警，系统侦测到“黑飞”无人机进入到目标区域后，能及时有效的启动处置程序，自动或手动进行有效处置，将危险阻击在核心保护区外；并对处置事件进行记录存档，以便于将来可举证、可回溯。'
                    }
                ]
            }
        ]
    },
    altitude:{
        title:'数字低空',
        summary:'北斗低空数智底座以时空位置服务为核心，基于网格和编码技术，融合运用大数据及二三维可视化技术，作为无人机行业管理数字化的重要支撑体系。通过北斗低空数智底座数据中台的建设，将所有低空无人机及行业应用产生的数据统一接入底座数据中台，并通过数据治理，形成行业标准化数据资源目录，将支撑其它应用示范及未来区域化相关低空经济领域的业务应用。',
        lists:[
            {
                pic:require('@/images/solution/altitude_1.png'),
                title:'北斗无人机飞行监管平台',
                info:'对无人机飞行要素全面审批管理，建立从资产、人员、任务、事件全方位闭环管理。对无人机运行数据（包括位置、高度、速度等）进行实时动态监测，对无人机飞行进行定位、跟踪、记录、分析，对越界飞行等状况进行及时告警和线路优化，为合法安全飞行提供有力保障。',
                subLists:[
                    
                ]
            },
            {
                pic:require('@/images/solution/altitude_2.png'),
                title:'北斗无人机飞行服务平台',
                info:'对无人机飞行任务多场景服务支撑，提供原子化无人机能力，为各个业主单位提供作业选择。为政府部门多种管控需求、企业多元管理与增值服务需求等提供针对性的解决方案及针对不同客户提供多种个性化方案，多维度助力无人机安全飞行。',
                subLists:[
                    
                ]
            },
            {
                pic:require('@/images/solution/altitude_3.png'),
                title:'北斗无人机数据中枢平台',
                info:'汇聚人、机、物、场的设施数据，建设数据分析共享体系，作为大系统的数据中心，助力管理部门对于“黑飞”现状的管控，为无人驾驶飞行器的运行提供全面、高效的信息服务，实现低空空域“看得见”“管得住”“飞得顺”，为我国无人机产业的快速、健康发展提供保障和助力。',
                subLists:[
                    
                ]
            },
            {
                pic:require('@/images/solution/altitude_4.png'),
                title:'北斗无人机运行态势一张图',
                info:'利用数字孪生技术对系统整体运行态势可视化展示，建设成为感知、触发、研判、流转、处置、留痕全流程可联动的一图通览平台，实现城市航路网、感知设施、航路环境等各监测领域的日常监测一图通览与预警多级联动调度，以及重大活动、重大节假日等专题监测与预警。',
                subLists:[
                    
                ]
            }
        ]
    },
    energy:{
        title:'数字能源',
        summary:'福信富通智慧能源解决方案结合北斗卫星导航、物联网和智能分析技术，对能源行业的设备、运行、维护等全要素实现动态监管，提高能源行业的生产效率和安全水平。通过北斗时空大数据底座，对能源行业的运行状态和趋势进行可视化分析，为能源行业的决策提供有力支持。此外，还能够实现设备的远程控制和监测，为能源行业的智能化发展提供有力支撑。',
        lists:[
            {
                pic:require('@/images/solution/energy_1.png'),
                title:'北斗 + 电力',
                info:'北斗电力管理系统依托北斗卫星定位系统，对电力传输各个环节进行实时监测和管理，确保电力安全可靠。该系统可以实现远程抄表、故障预警、线路巡检等功能，提高电力管理的效率和精度。同时，通过大数据分析和智能报表，可以为电力公司的生产管理、调度运行、检修维护等提供可靠的数据支持，实现电力生产和管理的数字化、智能化。该系统符合电力行业相关标准，提供灵活多样的部署方案，满足各种不同环境的需求。通过北斗电力管理系统的应用，可以提高电力供应的可靠性和稳定性，降低运营成本，为电力行业的发展提供有力支持。',
                subLists:[
                    {
                        pic:require('@/images/solution/energy_1_1.jpg'),
                        title:'北斗自组网通信应用解决方案',
                        info:'针对电网作业系统在缺乏运营商网络支持下的通信需求难题，建设了基于北斗无线应急通信的北斗自组网作业通信应用。该应用提高了应急响应速度，提高了工作效率，并提升了管理分析水平。通过该平台，一线作业人员可以实现定位调度和多网融合的通讯，以达成高效的协同工作。该应用在无线电基础上进行多网融合，为生产及应急救援等管理工作带来了便利。此外，基于对讲自组网技术，该应用还实现了图片数据的传输，使指挥中心能够获得更丰富的现场信息，有助于管理人员对事件进行分析与决策。对讲应急调度平台可以记录各群组对讲和位置数据，方便事后回溯，针对抢险救援中遇到的突发状况，可以全程记录指挥时间线，以修正应急预案。',
                    },
                    {
                        pic:require('@/images/solution/energy_1_2.jpg'),
                        title:'杆塔位移形变在线监测解决方案',
                        info:'输电线路杆塔位移形变在线监测系统，融合北斗卫星测量技术、传感技术和无线通信技术，对位于冰灾、雪灾、泥石流、山体滑坡多发区、煤矿采空区等不良地质区域内电线杆塔，进行倾斜角度实时监测，用于7*24小时采集杆塔姿态数据。当杆塔倾斜角度超过设定的阈值时，系统能够通过无线网络及时将预警信息发送给监控中心，提醒线路运行负责人了解现场受损情况，为应急指挥调度提供辅助决策依据。提供专业的杆塔终端技术服务，杆塔正常情况下，监测终端定时上报信息，即每天在固定时间上传杆塔当前时刻的位移值；当杆塔位移超出阈值后，则触发报警状态，立即上报异常信息给运维人员，同时规划抢险导航路线。有力推动电网数字化转型发展，支撑新型电力系统建设。',
                    },
                ]
            },
            // {
            //     pic:require('@/images/solution/energy_2.png'),
            //     title:'北斗 + 园区',
            //     info:'北斗园区管理系统依托北斗卫星定位系统，对园区内各类设施和活动进行实时监测和管理，确保园区安全有序运营。该系统可以实现智能巡检、智能监控、智能停车等功能，提高园区的运营效率和安全性。同时，通过大数据分析和智能报表，可以为园区的物业管理、安全管理、商业管理等提供可靠的数据支持，实现园区的数字化、智能化管理。该系统符合园区相关标准，提供灵活多样的部署方案，满足各种不同环境的需求。通过北斗园区管理系统的应用，可以提高园区的运营效率和管理水平，降低运营成本，为园区的可持续发展提供有力支持。',
            //     subLists:[
            //         {
            //             pic:require('@/images/solution/energy_2_1.jpg'),
            //             title:'智慧化工园区监管解决方案',
            //             info:'在化工园区中，安全生产是重中之重。北斗系统为化工园区提供了实时监测和安全管理服务。通过北斗系统提供的精确监测设备，可以实时监测化工装置、仓库等重要场所的温度、湿度、压力等关键指标，及时发现异常情况并采取相应措施，确保安全生产。同时，利用北斗定位模块可以实时监测巡检人员的位置和巡检情况，确保巡检工作的准确性和及时性，提高安全管理水平。此外，利用北斗系统的精准定位和实时监控功能，可以实现对危险品运输车辆的实时跟踪和监控，确保危险品运输的安全性和可靠性。通过北斗系统的应用，可以提高化工园区的安全管理水平和效率，降低安全风险，保障员工的生命财产安全，为化工行业的可持续发展提供有力保障。',
            //         },
            //         {
            //             pic:require('@/images/solution/energy_2_2.jpg'),
            //             title:'智慧物流园区监测解决方案',
            //             info:'在物流园区中，货物运输和车辆管理是核心业务。北斗系统为物流园区提供了精准定位和智能化管理服务。首先，通过搭载在停车设备上的北斗定位模块，可以实现智能停车管理，提高停车效率和安全性，减少乱停乱放和交通拥堵现象。其次，利用北斗系统的精准定位和实时监控功能，可以实现对物流车辆的实时跟踪和调度管理，提高车辆的利用率和运输效率。此外，通过北斗系统提供的监控设备，可以实时监控物流车辆的运行情况和货物装载情况，及时发现异常情况并采取相应措施，确保物流运输的安全性和可靠性。通过北斗系统的应用，可以提高物流园区的智能化管理水平和工作效率，降低运营成本，为客户提供更优质、高效的物流服务，推动物流行业的可持续发展。',
            //         },
            //     ]
            // },
            {
                pic:require('@/images/solution/energy_3.png'),
                title:'北斗 + AI',
                info:'北斗+AI是一种综合性的技术方案，它将北斗卫星定位系统与人工智能技术相结合，旨在为包括交通、物流、农业、公共安全等各个视频领域提供更精准、智能的服务。该系统利用北斗卫星定位技术实现高精度的定位和导航，并结合人工智能算法技术进行数据分析和预测，实现智能化管理和控制。系统提供灵活多样的部署方式，可以满足不同领域的需求，具有良好的可扩展性和兼容性，可以与其他系统进行高度集成，实现信息的共享和交互。通过采用北斗+AI方案，可以提高生产效率和管理水平，为社会发展提供有力支持。',
                subLists:[
                    {
                        pic:require('@/images/solution/energy_3_1.jpg'),
                        title:'非法入侵区域解决方案',
                        info:'北斗卫星导航系统与AI技术的结合，可以为非法入侵区域解决方案提供有力支持。首先，北斗系统可以精确提供入侵者的位置信息，帮助用户快速响应和处理。其次，通过AI技术对现场情况进行智能分析，可以识别入侵者的身份和行为，提高用户对现场情况的了解和掌控。此外，北斗+AI应用还可以提供预警功能，预测潜在的非法入侵风险，让用户提前采取措施，减少入侵事件的发生。最后，自动化处理功能如自动报警和警力调度，可以帮助用户更高效地处理入侵事件。该解决方案中可以提供精准位置信息、智能分析和预警、以及自动化处理等功能，提高用户对非法入侵的应对能力。',
                    },
                    {
                        pic:require('@/images/solution/energy_3_2.jpg'),
                        title:'海上风电船舶入侵解决方案',
                        info:'通过结合北斗卫星定位技术和人工智能技术，可以实现海上风电船舶的实时监测和管理，防止非法入侵和盗窃等行为。首先，利用北斗卫星定位技术对海上风电船舶进行实时监测和定位，结合人工智能技术对监测数据进行分析和预测，一旦发现异常行为，立即向管理人员发送报警信息，确保及时采取措施。其次，通过安装视频监控设备，对海上风电船舶进行全方位的监控和录像，同时利用人工智能技术进行人脸识别和目标追踪，识别陌生人并对其进行跟踪和监控，防止非法入侵和盗窃等行为。此外，与其他系统进行高度集成，实现信息的共享和交互，提高管理效率。可以有效地提高海上风电船舶的安全性和可靠性，防止非法入侵和盗窃等行为，保障风电场的安全和稳定运行，为海洋能源领域的可持续发展提供有力支持。',
                    },
                ]
            },
        ]
    },
    city:{
        title:'数字城市',
        summary:'福信富通智慧城市解决方案运用“北斗+”技术，利用北斗卫星的授时、通信、导航和遥感等基础能力，通过汇聚、处理、共享和分发时空数据，结合北斗卫星导航、物联网和云计算等军民两用技术，为应急管理、智能园区、智慧教育、智慧边防等各类场景应用提供支持，构建起网络化、智能化、精细化管理体系，为城市运营和发展提供更好的管控与服务能力，打造出一座全新的智慧城市。',
        lists:[
            {
                pic:require('@/images/solution/city_1.png'),
                title:'北斗 + 应急指挥应用',
                info:'北斗智慧应急指挥应用是一种基于现代信息技术的综合应急管理解决方案。该应用依托北斗卫星定位系统，旨在实现对各类紧急事件和突发情况的快速、高效响应与协调，不仅集成了各种资源和信息，还具备智能化分析和决策功能。在智慧应急指挥应用的加持下，政府相关部门能够实时监测和掌握紧急事件的发展情况，快速调动和协调各种应急资源，以最大程度地减少损失和风险。此外，通过配套实时通信和协作工具，能够促进不同部门之间的信息共享和紧密合作。智慧应急指挥应用在事前、事中和事后都发挥着重要作用。在事前，通过数据分析和模拟制定预案；在事中，监控事件、调度资源、迅速决策；在事后，总结经验、改进策略。',
                subLists:[
                    {
                        pic:require('@/images/solution/city_1_1.jpg'),
                        title:'北斗应急支撑服务平台及终端',
                        info:'北斗应急支撑服务平台是一个集成化的系统，专注于应急管理与协调。该平台为应急管理提供了全套解决方案，包括事件填报、事件处理、任务调度、预案响应、应急指挥、视频巡查、资源管理以及值班管理等功能。平台配合智能应急终端提供全面的应急响应和协调服务，重点实现位置追踪和北斗应急通信、协调指挥功能。智能终端包括多种设备，如北斗对讲机，可以为团队成员提供即时通信渠道；北斗智能安全帽，则将位置信息与实时通讯相结合，提供更便捷的通信工具；北斗定位终端能够实现精确的位置定位，方便对人员和资源的实时监控。这些智能终端不仅提供了高精度位置追踪功能，还融合了高质量北斗通信服务，确保在紧急情况下的信息传递畅通无阻。',
                    },
                ]
            },
            {
                pic:require('@/images/solution/city_2.png'),
                title:'智慧城管应用',
                info:'智慧城管是基于现代信息技术的综合解决方案，主要针对渣土车管理、环卫工人智慧管理等领域。在渣土车管理方面，智慧城管平台通过智能车载终端感知，实现对渣土车辆的运营管理、违规行为监测、应急调度等功能。平台以行业标准为基础，提供一体化数字解决方案，降低运营成本，提升车辆精细化管理水平。通过数据分析，平台筛查出企业安全问题，协助监督行车安全管理工作，提高渣土车运营的安全性。在环卫工人的智慧管理方面，通过智能手表、手环和智能工牌，实现对环卫工人的定位、考勤、工作情况监测等功能。这不仅有助于提高工作效率，还能够评估工人的工作表现，优化资源分配，提升服务质量。',
                subLists:[
                    {
                        pic:require('@/images/solution/city_2_1.jpg'),
                        title:'北斗城管平台及终端',
                        info:'北斗城管平台是一个综合性的系统，旨在为用户提供多种功能。在城管平台中，监控中心是核心组成部分，能够实时监测智能终端的位置和状态。健康管理模块则能够通过智能手表或手环等设备，监测用户的健康状况，例如心率、步数等，以提供个性化的健康管理建议。统计分析功能则允许用户查看平台报警统计、日志、区域围栏情况、打卡记录以及电量监测等数据，以便更好地进行数据分析和决策。在设备终端方面，智能手表、手环和智能工牌是核心设备。智能手表和手环不仅可以实现位置定位，还能监测用户的健康状况，并将相关数据传输至城管平台，进行健康管理和统计分析。智能工牌则可以用于身份识别，进出特定区域，考勤远程打卡、工作情况监测等，还可以与平台进行通信。',
                    },
                ]
            },
            {
                pic:require('@/images/solution/city_3.png'),
                title:'智慧园区应用',
                info:'智慧园区安全风险智能化管控，专注于重大危险源在线监测、风险管控、特殊作业管理、危废管理、隐患排查治理、人员车辆进出园区管理和应急管理等领域。通过集成北斗定位、视频监控等技术，高清摄像头以及北斗智能终端，实现多维度信息的数据采集、挖掘、分析和处理，从而提供全面的园区管理支持。工业园区通过数字园区一张图，更加直观展示园区里的人、车、物、事件、作业实况等信息，实现工业园区的全屏可视化和精细化管理。重要的是，园区管理为特种车辆的安全运行、运力调配、智能决策等方面提供支持，提高化工园区整体管理水平。',
                subLists:[
                    {
                        pic:require('@/images/solution/city_3_1.jpg'),
                        title:'智慧园区管理平台及终端',
                        info:'智慧园区管理平台是基于现代信息技术的综合解决方案，旨在提升园区的管理效率、安全性和可持续发展。该平台整合了资源调度、环境监测、能耗管理、设备监控、安全管理、智能停车和交通管理、数据分析等多项功能，为园区管理者提供了实时的信息和决策支持。通过数据分析，平台提供节能减排建议、设备维护提示，同时监控环境质量、安全隐患，实现预警和应急预案。移动应用和远程监控功能让管理者可以随时掌握园区动态。在园区管理平台中，智能终端起着关键作用。高清摄像头用于园区危险源在线监测，实时监测潜在危险源，并发出预警。特种车辆搭载北斗定位车载终端，实时监测车辆的运行情况，实现车辆的精细化管理和安全监控。员工佩戴智能工牌，有助于园区实现人员管理，有效提升了工业园区的管理水平，推动园区的持续发展。',
                    },
                ]
            },
            {
                pic:require('@/images/solution/city_4.png'), 
                title:'林草消防应用',
                info:'林草消防专注于林业消防的管理与应急响应，涵盖了对林业人员的管理、定位、轨迹回放、边界管理、地图标绘、信息通信等功能，通过北斗高精度定位和通信技术，实现了对林业人员的实时定位、轨迹记录与回放，有效管理辖区范围。同时提供边界管理和地图标绘功能，帮助确保林区边界的安全，并绘制地理信息用于决策分析，信息通信工具促进了信息共享与协作。此外，林草消防在灾害预防和应急响应方面，通过巡检系统、视频监控、卫星遥感捕捉和无人机巡防等手段，全方位监测林区情况，预防潜在灾害，对林区内的火情、病虫害等问题进行实时监控，及时采取措施。',
                subLists:[
                    {
                        pic:require('@/images/solution/city_4_1.jpg'),
                        title:'林草消防监管平台及终端',
                        info:'林草消防监管平台是专为林业消防而设计的综合性系统，以现代信息技术为支撑，集成了火灾监控、预警、位置追踪、应急响应和智能终端等关键功能。通过部署北斗手持对讲机、北斗无人机和北斗高清摄像头等设备，能实现火情实时监测、火险等级评估、火灾位置追踪，并协调消防人员和资源的应急响应，为消防人员提供全面的救援数据支撑，提高火灾防范和应急响应的效率，有效保障了森林和草原的安全以及自然资源的保护，增强了林草消防的监督和管理能力，全面提升林业消防的水平。',
                    },
                ]
            },
        ]
    },
}