


export const honorsInfo = [
    {
        pic:require('@/images/honors/1.jpg'),
        title: `国家专精特新"小巨人"企业`
    },
    {
        pic:require('@/images/honors/2.jpg'),
        title: `国家高新技术企业`
    },
    {
        pic:require('@/images/honors/4.jpg'),
        title:'福建省科学技术进步奖二等奖'
    },
    {
        pic:require('@/images/honors/7.jpg'),
        title:'福建省未来独角兽”创新企业'
    },
    {
        pic:require('@/images/honors/5.jpg'),
        title: `工人先锋号`
    },
    {
        pic:require('@/images/honors/10.png'),
        title: `福建省信息技术应用创新典型解决方案`
    },
    // {
    //     pic:require('@/images/honors/11.jpg'),
    //     title:'劳模和工匠人才创新工作室'
    // },
    // {
    //     pic:require('@/images/honors/3.jpg'),
    //     title:'诚信企业'
    // },
    // {
    //     pic:require('@/images/honors/6.jpg'),
    //     title:'军民融合副会长单位'
    // },
    // {
    //     pic:require('@/images/honors/8.jpg'),
    //     title:'福建省智慧海洋产业联盟-副理事单位'
    // },
    // {
    //     pic:require('@/images/honors/9.jpg'),
    //     title:'鼓楼区理事单位'
    // },
];