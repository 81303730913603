<template>
    <div class="footer">
        <div class="-footer-container">
            <div class="-footer-top">
                <div class="-footer-t-l">
                    <div>
                        <span class="-tit">联系电话</span>
                        <h5>400-887-1670</h5>
                    </div>
                    <div>
                        <span class="-tit">电子邮箱</span>
                        <h6>fxft@fxft.net</h6>
                    </div>
                </div>
                <div class="-footer-t-m">
                    <div>
                        <span class="-tit">公司地址</span>
                        <h6>福建省福州市鼓楼区工业路611号海峡技术转移中心15-16层</h6>
                    </div>
                    <div>
                        <span class="-tit">电话传真</span>
                        <h6>0591-87588559</h6>
                    </div>
                </div>
                <div class="-footer-t-r">
                    <img :src="qrcode" />
                    <h6> "福信富通" 微信公众号</h6>
                </div>
            </div>
            <div class="-footer-bottom">
                <span>福信富通科技股份有限公司 版权所有</span>
                <span>备案号：闽ICP备10207736号-3</span>
                <span class="-a-btn" @click="toSafe">公网安备35010202001788号</span>
                <span class="-a-btn" @click="showLawer">法律声明</span>
                <span class="-a-btn" @click="toLink">联系我们</span>
            </div>
        </div>

    </div>
    <div class="lawer-container" v-show="showState">
        <div class="lawer-modal">
            <i @click="closeModal" class="-close"
                style="background-image: url(&quot;data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAASBAMAAABP1yhnAAAALVBMVEUAAABycXFycXFycXFycXFycXFycXFycXFycXFycXFycXFycXFycXFycXFycXEjJVBmAAAADnRSTlMAFdRXVsFAINOHsrFpgqNrhQgAAABpSURBVAjXY6hNYICCuCcgknFrAcO6dw5AlsS7CQxC74CCjH3vEhgY9d45AIUeAyWAgkCeAZAFpA6BhMCC70BCYEGgEJQF0o6QheuACD2GmAIiQTywEJibwLAPaBmIPwHmqqUFDJUwlwIA/Uoy1oU7eW4AAAAASUVORK5CYII=&quot;);"></i>
            <div class="-content">
                <p>以下声明条款适用于所有访问本公司网站的浏览者，福信富通科技股份有限公司（以下简称"本公司"或"福信富通"）保留随时更改该声明内容的权利。请在访问此网站之前认真阅读这些条款内容，尤其是"免责条款"、"适用法律和争议解决条款"。您访问、浏览和/或使用本网站即代表您接受以下条款。若您不同意下列任何条款，请立即停止访问本网站。
                </p>

                <p>1. 本网站所刊登的所有有关本公司的资料信息，包括但不限于文本、图片、数据、视频等，力求及时、准确、真实、完整，本公司对以上资料拥有最终解释权。
                    本公司会尽力审核本网站上信息，但对该等信息不做任何保证（如可适用性、正确性、完整性、及时性、不侵权等）。对该等信息及其使用，本公司均不负任何责任。</p>

                <p>2. 本公司可随时对网站内容进行修改调整，而不另行通知，若您想要获得最新信息，请定时访问本网站。</p>

                <p>3.
                    任何人不得以任何方式修改本网站上的资料内容，也不能复制或转载将其用于任何公共或商业用途，更不能将本网站内容用于其他任何非法行为。未经授权而随意使用本网站上的任何资料的行为，本公司保留追究相关法律责任的权利。
                </p>

                <p>4.
                    本公司会提供“联系我们”板块与网站浏览者进行互动，我们会定期或不定期查看并管理用户在本网站上"联系我们"板块的留言信息，但是，本公司不对此类留言的内容承担任何责任。原则上，我们不会向第三方公开留言内容，但是若留言内容包含被本公司视为侮辱性的、诽谤性的、淫秽或因其它原因不可接受的材料，用户应承担任何因此有可能产生的民事侵权或刑事法律责任。本公司将保留删除此类留言的权利，且以上条款的解释权、修改权归本公司所有。
                </p>

                <p>5. 为方便您使用，本网站可能含有通向第三方网站的链接。这些第三方网站链接不代表本公司对第三方网站的内容的认同、批准和/或支持，也不是用于宣传或广告目的。本公司不对第三方网站上的内容做任何保证,
                    对第三方网站不负任何责任。对是否使用第三方网站及其内容，您应独立判断。</p>

                <p>6. 本网站中使用的所有商标、标志等皆归本公司或许可方所有。未经本公司书面许可，任何人不得以任何方式使用本公司的商标、标志等。</p>

                <p>7. 本网站所载的所有材料或内容受著作权法的保护，著作权由本公司或许可方所有，但注明引用其他方的内容除外。</p>

                <p>8. 免责条款
                    本公司对因使用本网站内容和/或无法访问本网站而导致的间接性的、特殊的、附带的、惩罚性的和/或后果性的损失,利润损失和数据丢失或业务中断等损失不承担任何责任。本公司对由于计算机病毒或其他恶意软件、程序、代码等以任何方式对您的计算机系统造成的任何负面影响，或造成您的系统崩溃或出现其他技术问题等不承担责任。
                </p>

                <p>9.
                    根据中华人民共和国法律规定，本网站尊重并保护浏览者在网站中提供的个人信息和隐私权。本网站中的隐私政策适用于您对本网站的使用。您使用本网站即代表您同意该隐私政策，本公司可以根据该隐私政策收集、使用、存储、分享相关个人信息。
                </p>

                <p>10. 适用法律和争议解决条款
                    本网站及本法律声明的解释、效力、履行、纠纷解决及其他未尽事宜，均适用中华人民共和国大陆地区法律。如您与本公司发生任何争议，首先应友好协商解决；协商不成的，您同意将争议或纠纷提交福建省福州市有管辖权的人民法院管辖。
                </p>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>

import qrcode from '@/images/qrcode.png';
import { useRoute,useRouter } from 'vue-router';

import { computed, ref, Ref, watch, onMounted, onUnmounted } from 'vue';
const showState: Ref<boolean> = ref(false);
const showLawer = () => {
    showState.value = true;
}
const closeModal = () => {
    showState.value = false;
}

const router = useRouter();
const toLink = ()=>{
    router.push(`contactus`);
}
const toSafe = ()=>{
    window.open('https://beian.mps.gov.cn/','_blank')
}
</script>
<style lang="scss">
.footer {
    background-color: rgb(254, 254, 254);
    height: 320px;
    color: rgb(59, 59, 59);

    .-footer-container {
        width: 1200px;
        margin: 0 auto;

        .-tit {
            font-size: 16px;
            height: 38px;
            line-height: 38px;
            ;
        }

        h5 {
            font-size: 22px;
        }

        h6 {
            font-size: 16px;
            font-weight: normal;
        }

        .-footer-top {
            display: flex;
            height: 230px;
            justify-content: space-between;
        }

        .-footer-t-l {
            >div {
                height: 50%;
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
        }

        .-footer-t-m {
            width: 600px;

            >div {
                height: 50%;
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
        }

        .-footer-t-r {
            padding-top: 30px;

            img {
                display: block;
                margin: 0 auto;
            }

            h6 {
                height: 40px;
                line-height: 40px;
                ;
            }
        }

        .-footer-bottom {
            border-top: 3px solid rgb(59, 59, 59);
            display: flex;
            height: 80px;
            line-height: 80px;
            align-items: center;

            span {
                margin-right: 20px;
            }
        }
    }

    .-a-btn:hover {
        text-decoration: underline;
        cursor: pointer;
    }
}

.lawer-container {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 19999;

    .lawer-modal {
        width: 1000px;
        height: 600px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #fff;
        position: absolute;
        z-index: 100;

        .-close {
            position: absolute;
            z-index: 101;
            top: 20px;
            right: 30px;
            width: 18px;
            height: 18px;
            transition: all 0.2s linear;
            cursor: pointer;
        }
        .-content{
            box-sizing: border-box;
            height:100%;
            padding:50px;
            overflow: hidden;
            font-size: 12px;
            color:#555;
        }
    }
}</style>