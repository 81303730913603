
// eslint-disable-next-line
//@ts-ignore
//todo 报错原因 , 但linkWall 是ok的
import { InjectionKey } from 'vue';

import {createStore,useStore as baseUseStore,Store} from 'vuex';

import {userInfo} from '@/customDeclare/backEndDeclare';
import {viewPageInfo} from '@/customDeclare/frontEndDeclare';


export interface State{
    userInfo:{
        userInfo:userInfo
    },
    viewPageInfo:viewPageInfo
}

export const key:InjectionKey<Store<State>> = Symbol();

export const store = createStore({
    modules:{
        userInfo:{
            state:{
                userInfo:{}
            },
            mutations:{
                setUserInfo(state:State['userInfo'],userInfo:userInfo){
                    state.userInfo = {...userInfo};
                },
                clearUserInfo(state:any){
                    state.userInfo = {
                        userId:'',
                        userName:''
                    };
                }
            }
        },
        viewPageInfo:{
            state:{
                showHead:true,
                showCrumb :true,
                login:false
            },
            mutations:{
                setShowHead(state:viewPageInfo,status:boolean){
                    state.showHead = status;
                },
                setShowCrumb(state:viewPageInfo,status:boolean){
                    state.showCrumb = status;
                },
                setLoginStatus(state:viewPageInfo,status:boolean){
                    state.login = status;
                }
            }
        }
    }
})

export function useStore(){
    return baseUseStore(key);
}

export default store;