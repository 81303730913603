<template>
     <div class="-solution-container">
        <div :class="`-top-banner -${activeName}`"></div>
        <div class="-solution-tabs">
            <div class="-solution-tabs-content">
                <h6 :class="{'-active':activeName === subMenu.link}" v-for="(subMenu,i) in solutionMenu.subTitle"
                    @click="linkTo(subMenu.link as string)"
                    v-text="`北斗 + ${subMenu.title}`" :key="i"></h6>
            </div>
        </div>
        <div class="-solution-detail">
            <div class="-solution-summary">
                <h4 v-text="currentSolution.title"></h4>
                <div class="-info" v-text="currentSolution.summary"></div>
            </div>
            <div class="-solution-content">
                <div :class="`-bg -${activeName}`"></div>
                <div class="-solution-content-inner">
                    <h6>应用场景</h6>
                    <div class="-solution-item-container">
                        <div class="-solution-item" v-for="(item,i) in currentSolution.lists" :key="i" :class="{'-short':activeName === 'altitude'}">
                            <img :src="item.pic" />
                            <div class="-bot">
                                <div class="-item-detail">
                                    <h6 v-text="item.title"></h6>
                                    <p v-text="item.info"></p>
                                </div>
                                <div class="-solution-more-panel">
                                    <a v-for="(subItem,k) in item.subLists" :key="k" @click="showModal(subItem)">
                                        <arrowRightIcon></arrowRightIcon>
                                        <span v-text="subItem.title"></span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="-solution-modal" v-show="modalVisible">
            <div class="-solution-modal-content">
                <i class="-close" :style="{backgroundImage:`url(${closeIcon})`}" @click="closeModal"></i>
                <div class="-l">
                    <img :src="currentModalInfo.pic" />
                </div>
                <div class="-r">
                    <h6 v-text="currentModalInfo.title"></h6>
                    <p  v-text="currentModalInfo.info"></p>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
    import { useRoute,useRouter } from 'vue-router';
    import { computed,ref,Ref } from 'vue';
    import {solutionMenu} from '../../dic/menuInfo';
    import arrowRightIcon from '../../common/arrowRightIcon.vue';
    import {solutionInfo} from '../../dic/solutionInfo';
    import closeIcon from '@/images/close.png';

    const route = useRoute();
    const activeName = computed(()=>{
        return route.params.type;
    });
    const router = useRouter();
    const linkTo = (link:string)=>{
        router.push(`${solutionMenu.parentLink}/${link}`);
    }

    const currentSolution = computed(()=>{
        return solutionInfo[activeName.value as keyof typeof solutionInfo ];
    })

    const currentModalInfo:Ref<any> = ref({});
    const modalVisible:Ref<boolean> = ref(false);
    const showModal = (info:any)=>{
        currentModalInfo.value = info;
        modalVisible.value = true;
    }
    const closeModal = ()=>{
        modalVisible.value = false;
    }
    

</script>
<style lang="scss">

    $mainColor: #325ebf; //#e7871d;        
    .-solution-container{
        .-top-banner{
            width:100%;
            height:590px;
            background-position: center center;
            &.-traffic{
                background-image:url('~@/images/info_1.png');
            }
            &.-ocean{
                background-image:url('~@/images/info_2.png');
            }
            &.-energy{
                background-image:url('~@/images/info_4.png');
            }
            &.-altitude{
                background-image:url('~@/images/info_3.png');
            }
            &.-city{
                background-image:url('~@/images/info_5.png');
            }
        }
        .-solution-tabs{
            width:100%;
            min-width: 1200px;
            border-bottom:5px solid #dbdbdb;
            position: -webkit-sticky;
            position: sticky; 
            top: 0; 
            background-color: #fff;
            z-index: 19;
            .-solution-tabs-content{
                width:1200px;
                height:84px;
                display: flex;
                justify-content: flex-start;
                margin:0 auto;
                h6{
                    font-size: 20px;
                    font-weight: normal;
                    width:206px;
                    height:84px;
                    text-align: center;
                    line-height: 84px;
                    color:#464343;
                    position: relative;
                    cursor: pointer;
                    &:hover{
                        color:$mainColor;
                    }
                    &.-active{
                        color:$mainColor;
                        &:after{
                            content:'';
                            position: absolute;
                            height:5px;
                            width:100%;
                            bottom:-5px;
                            left:0;
                            background-color: $mainColor;
                        }
                    }
                }
            }
        }
        .-solution-detail{
            .-solution-summary{
                width:1200px;
                margin:0 auto;
                h4{
                    font-size: 42px;
                    text-align: center;
                    color:$mainColor;
                    position: relative;
                    height:140px;
                    line-height: 140px;
                    padding:40px 0 10px 0;
                    &::after{
                        content:'';
                        position: absolute;
                        bottom:40px;
                        background:#5a96ff;
                        height:4px;
                        width:100px;
                        left:50%;
                        transform: translate(-50%,0);
                    }
                }
                .-info{
                    color:#464343;
                    font-size: 18px;
                    line-height: 38px;
                    text-indent: 2em;
                    padding-bottom:30px;
                }
            }
            .-solution-content{
                // height:1200px;
                position: relative;
                .-bg{
                    width:100%;
                    height:100%;
                    background-size: cover;
                    position: absolute;
                    top:0;
                    left:0;
                    opacity: 0.1;
                    &.-traffic{
                        background-image:url('~@/images/info_1.png');
                    }
                    &.-ocean{
                        background-image:url('~@/images/info_2.png');
                    }
                    &.-energy{
                        background-image:url('~@/images/info_4.png');
                    }
                    &.-altitude{
                        background-image:url('~@/images/info_3.png');
                    }
                    &.-city{
                        background-image:url('~@/images/info_5.png');
                    }
                }
            }
            .-solution-content-inner{
                width:1200px;
                margin:0 auto;
                padding:40px;
                > h6{
                    font-size:42px;
                    color:$mainColor;
                    position: relative;
                    text-align: center;
                    height:80px;
                    line-height: 80px;
                    &::after{
                        content:'';
                        position: absolute;
                        bottom:0px;
                        background:#5a96ff;
                        height:4px;
                        width:160px;
                        left:50%;
                        transform: translate(-50%,0);
                    }
                }
                .-solution-item-container{
                    display: flex;
                    justify-content: space-between;
                    padding-top:40px;
                    position: relative;
                    z-index: 9;
                    flex-wrap: wrap;
                    .-solution-item{
                        width:586px;
                        height:800px;
                        border-radius: 8px;
                        overflow: hidden;
                        background-color: #fff;
                        flex:0 0 auto;
                        margin-bottom:36px;
                        border:1px solid #e1e1e1;
                        &.-short{
                            height:600px;
                        }
                        &:hover{
                            box-shadow: 1px 1px 10px rgba(0,0,0,0.3);
                        }
                        img{
                            height:330px;
                            width:100%;
                            display: block;
                        }
                        .-bot{
                            padding:0 40px;
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;
                            height:calc(100% - 330px);
                            box-sizing: border-box;
                            .-item-detail{
                                h6{
                                    padding-top:20px;
                                    font-size: 24px;
                                    color:#727171;
                                    line-height: 60px;
                                }
                                p{
                                    text-indent: 2em;
                                    font-size: 16px;
                                    color:#727171;
                                    line-height: 30px;
                                    margin:0;
                                }
                            }
                            .-solution-more-panel{
                                padding-bottom:20px;
                                a{
                                    display: block;
                                    font-size: 18px;
                                    line-height: 40px;
                                    color:#5a96ff;
                                    height:40px;
                                    display: flex;
                                    align-items: center;
                                    cursor: pointer;
                                    > svg{
                                        margin-right:8px;
                                    }
                                    &:hover{
                                        text-decoration: underline;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .-solution-modal{
            width:100%;
            height:100%;
            position: fixed;
            top:0;
            left:0;
            background-color: rgba(0,0,0,0.6);
            z-index: 99;
            .-solution-modal-content{
                width:1200px;
                height:500px;
                top:50%;
                left:50%;
                transform: translate(-50%,-50%);
                background-color: #fff;
                position: absolute;
                z-index: 100;
                border-radius: 10px;
                box-sizing: border-box;
                padding:50px;
                display: flex;
                .-close{
                    position: absolute;
                    z-index: 101;
                    top:50px;
                    right:50px;
                    width:18px;
                    height:18px;
                    transition: all 0.2s linear;
                    cursor: pointer;
                    &:hover{
                        transform: rotate(180deg);
                    }
                }
                .-l{
                    width:540px;
                    height:400px;
                    margin-right:45px;
                    flex:0 0 auto;
                    img{
                        display: block;
                        width:100%;
                        height:100%;
                    }
                }
                .-r{
                    width:520px;
                    flex:0 0 auto;
                    color:#727171;
                    h6{
                        line-height: 80px;
                        font-size: 26px;;
                    }
                    p{
                        margin:0;
                        font-size: 16px;
                        line-height: 30px;
                        text-indent: 2em;
                    }
                }
            }
        }
    }
</style>