<template>
     <div class="-science-container">
        <div class="-top-banner"></div>
        <div class="-science-detail">
            <div class="-science-item">
                <h5>北斗系统概述</h5>
                <p>北斗卫星导航系统（以下简称北斗系统）是中国着眼于国家安全和经济社会发展需要，自主建设运行的全球卫星导航系统，是为全球用户提供全天候、全天时、高精度的定位、导航和授时服务的国家重要时空基础设施。 </p>
                <p>北斗系统提供服务以来，已在交通运输、农林渔业、水文监测、气象测报、通信授时、电力调度、救灾减灾、公共安全等领域得到广泛应用，服务国家重要基础设施，产生了显著的经济效益和社会效益。基于北斗系统的导航服务已被电子商务、移动智能终端制造、位置服务等厂商采用，广泛进入中国大众消费、共享经济和民生领域，应用的新模式、新业态、新经济不断涌现，深刻改变着人们的生产生活方式。</p>
                <div class="-summary"></div>
            </div>
        </div>
        <div class="-science-detail -bg">
            <div class="-science-item">
                <h5>发展历程</h5>
                <h6>北斗“三步走”发展战略</h6>
                <div class="-sys"></div>
            </div>
        </div>
        <div class="-science-detail -bg2">
            <div class="-science-item">
                <h5>北斗三号7种服务</h5>
            </div>
        </div>
        <div class="-science-detail">
            <div class="-science-item">
                <h5>新时代北斗精神</h5>
                <p>2020年6月23日，随着最后一颗组网卫星成功发射，北斗三号全球卫星导航系统完成全球星座部署；2020年7月31日，北斗三号全球卫星导航系统正式建成开通，标志着我国建成独立自主、开放兼容的全球卫星导航系统，成为世界上第三个独立拥有全球卫星导航系统的国家。</p>
                <div class="-sprite-content">
                    <div class="-sprite-item -b1">
                        <h6>自主创新</h6>
                        <span>是北斗工程的核心价值</span>
                    </div>
                    <div class="-sprite-item -b2">
                        <h6>自主创新</h6>
                        <span>是北斗工程的核心价值</span>
                    </div>
                    <div class="-sprite-item -b3">
                        <h6>自主创新</h6>
                        <span>是北斗工程的核心价值</span>
                    </div>
                    <div class="-sprite-item -b4">
                        <h6>自主创新</h6>
                        <span>是北斗工程的核心价值</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="-science-detail -lab">
            <div class="-science-item">
                <h5>北斗开放实验室</h5>
                <p>北斗开放实验室是北斗领域首个资源开放共享平台，是以推动“北斗应用”为目标的、非营利的开放合作与资源共享服务平台。北斗开放实验室本着“融合、开放、合作、共赢”的理念，以“联合优势单位、释放优质资源、创新人才培养、助推北斗发展”为目标宗旨，以“释放优质资源和创新人才培养”为核心，助推北斗产业应用发展。</p>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
    
</script>
<style lang="scss">

    $mainColor:#e7871d;        
    .-science-container{
        .-top-banner{
            width:100%;
            height:590px;
            background-position: center center;
            background-image:url('~@/images/science/top.png');
        }
        .-science-detail{
            .-science-item{
                width:1200px;
                margin:0 auto;
                padding:25px 0 15px 0;
                h5{
                    font-size: 42px;
                    line-height: 114px;
                    color:#325ebf;
                    position: relative;
                    text-align: center;
                    margin:0 0 20px 0;
                    &:after{
                        content:'';
                        width:108px;
                        height:5px;
                        position: absolute;
                        background-color: #5a96ff;
                        bottom:15px;
                        left:50%;
                        transform: translate(-50% ,0);
                    }
                }
                p{
                    margin:0;
                    color:#464343;
                    font-size: 18px;
                    line-height: 38px;
                    text-indent: 2em;
                }
                .-summary{
                    background-image:url('~@/images/science/summary.png');
                    background-position: center;
                    width:100%;
                    height:260px;
                    background-repeat: no-repeat;
                }
            }
            &.-bg{
                background-color: #f4f5f9;
                background-image:url('~@/images/science/bg_1.png');
                width:100%;
                height:950px;
                background-position: top center;
                background-repeat: no-repeat;
                h6{
                    font-size: 42px;
                    line-height: 80px;
                    padding-top:10px;
                    color:#5a96ff;
                    text-align: center;
                    margin:0;
                    font-weight: normal;
                }
                .-sys{
                    background-image:url('~@/images/science/sys.png');
                    background-position: center;
                    width:100%;
                    height:680px;
                    background-repeat: no-repeat;
                }
            }
            &.-bg2{
                background-color: #f4f5f9;
                background-image:url('~@/images/science/server.png');
                width:100%;
                height:810px;
                background-position: top center;
                background-repeat: no-repeat;
            }
            .-sprite-content{
                display: flex;
                justify-content: space-around;
                padding:0 20px;
                box-sizing: border-box;
                padding:50px 0;
                .-sprite-item{
                    width:235px;
                    height:153px;
                    color:#fff;
                    text-align: center;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    &.-b1{
                        background-image:url('~@/images/science/sprite.png');
                    }
                    &.-b2{
                        background-image:url('~@/images/science/sprite_2.png');
                    }
                    &.-b3{
                        background-image:url('~@/images/science/sprite_3.png');
                    }
                    &.-b4{
                        background-image:url('~@/images/science/sprite_4.png');
                    }
                    > h6{
                        font-size: 28px;
                        line-height: 46px;
                        margin: 0;
                    }
                    >span{
                        display: block;
                        font-size: 18px;
                        line-height: 38px;
                    }
                }
            }
            &.-lab{
                height:730px;
                background-image:url('~@/images/science/lab.png');
                h5{
                    color:#fff;
                    &::after{
                        background-color: #fff;;
                    }
                }
                p{
                    color:#fff;
                }
            }
        }
    }
</style>