<template>
    <svg 
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        width="10px" height="17px">
        <path fill-rule="evenodd"  fill="rgb(90, 150, 255)"
        d="M8.656,8.849 L2.006,15.648 C1.547,16.118 0.803,16.118 0.344,15.648 C-0.115,15.179 -0.115,14.418 0.344,13.949 L6.163,7.1000 L0.344,2.050 C-0.115,1.581 -0.115,0.820 0.344,0.351 C0.803,-0.119 1.547,-0.119 2.006,0.351 L8.656,7.150 C9.115,7.619 9.115,8.380 8.656,8.849 Z"/>
    </svg>
</template>

<script lang="ts" setup>


</script>
<style lang="scss">
</style>