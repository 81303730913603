<template>
     <div class="-hr-container">
        <div class="-top-banner"></div>
        <div class="-hr-detail">
            <div class="-hr-item">
                <h5>人才理念</h5>
                <p>人才是企业发展之本、竞争之本。福信富通科技股份有限公司始终视人才为企业第一资源，把推进企业和员工共同发展作为人才战略的根本任务，为各类人才提供充分施展才华的舞台。同时，通过建立公平的竞争机制和畅通的职业发展通道，充分调动每一位员工的积极性、主动性和创造性，实现企业与员工共同发展、共同成长。</p>
                <div class="-hr-list">
                    <span>
                        <em>在人才选择方面 ,</em>
                        我们以开放包容的心态，吸引并接纳各类人才，注重个人品质和专业素养，构建一个充满活力、富有创新精神的大家庭；
                    </span>
                    <span>
                        <em>在人才发展方面 ,</em>
                        我们充分尊重员工的价值诉求、高度重视个体的成长，持续为奋进者提供可以施展才华、实现人生抱负的舞台和机会；
                    </span>
                    <span>
                        <em>在人才评价方面 ,</em>
                        我们坚持以德为先，关注日常工作表现，公平公正地衡量每一位员工所付出的努力和创造的价值，让有为者脱颖而出。
                    </span>
                    <span>
                        <em>在人才激励方面 ,</em>
                        我们关注业绩达成能力、团队协作能力和学习能力，为有为者提供广阔的发展空间和具有竞争力的待遇，全面激发团队的创造活力。
                    </span>
                </div>
            </div>
        </div>
        <div class="-hr-detail -grey">
            <div class="-hr-item">
                <h5>学习发展</h5>
                <p>福信富通坚持“以人为本、责任诚信、创新进取、追求卓越”的经营原则，重视培养人才、团结人才、成就人才，构筑人才发展的广阔平台。</p>
                <div class="-hr-list-2">
                   <div class="-hr-pr-content">
                        <div class="-bg -bg1">
                            职业发展<br />通道
                        </div>
                        <div class="-p">
                            公司充分尊重人才的不同个性特征，鼓励员工将自身发展与公司长期规划相结合，建立通畅的职业发展通道，促进员工个人和企业的共同的发展。
                        </div>
                   </div>
                   <div class="-hr-pr-content">
                        <div class="-bg -bg2">
                            企业员工<br />培训
                        </div>
                        <div class="-p">
                            公司努力为每一位员工创造良好的发展平台及各种学习机会，帮助员工提高专业知识和工作技能，并鼓励员工利用业余时间自我学习。
                        </div>
                   </div>
                   <div class="-hr-pr-content">
                        <div class="-bg -bg3">
                            专家<br />工作站
                        </div>
                        <div class="-p">
                            2017年12月，福信富通获得专家工作站授牌，成为福信富通创新人才培养的重要平台之一。
                        </div>
                   </div>
                </div>
            </div>
        </div>
        <div class="-hr-detail">
            <div class="-hr-item">
                <h5>人才招聘</h5>
            </div>
            <div class="-hr-item">
                <div class="-jd-info" v-for="(item,i) in listItem" :key="i">
                    <div class="-need-name" v-text="item.title">
                    </div>
                    <div class="-need-info" v-html="item.content">

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>

    import { demoDao } from '@/dao/demoDao';
    import { computed,ref,Ref } from 'vue';

    const listItem = ref([]);

    const getList = ()=>{
        demoDao.getHrList().then(data=>{
            console.log(data);
            data.forEach(item=>{
                listItem.value.push({
                    title:item.title.rendered,
                    content:item.content.rendered
                })
            })
        })
    }
    getList();
    const clutureList = [
        {
            title:'核心价值观',
            sub:'科技驱动、变革创新、务实奋进、合作共赢'
        },
        {
            title:'核心价值观',
            sub:'科技驱动、变革创新、务实奋进、合作共赢'
        },
        {
            title:'核心价值观',
            sub:'科技驱动、变革创新、务实奋进、合作共赢'
        },
        {
            title:'核心价值观',
            sub:'科技驱动、变革创新、务实奋进、合作共赢'
        }
    ]
</script>
<style lang="scss">

    $mainColor:#e7871d;        
    .-hr-container{
        .-top-banner{
            width:100%;
            height:590px;
            background-position: center center;
            background-image:url('~@/images/hr/banner.png');
        }
        .-hr-detail{
            .-hr-item{
                width:1200px;
                margin:0 auto;
                padding:25px 0 15px 0;
                h5{
                    font-size: 42px;
                    line-height: 114px;
                    color:#325ebf;
                    position: relative;
                    text-align: center;
                    margin:0 0 20px 0;
                    &:after{
                        content:'';
                        width:108px;
                        height:5px;
                        position: absolute;
                        background-color: #5a96ff;
                        bottom:15px;
                        left:50%;
                        transform: translate(-50% ,0);
                    }
                }
                p{
                    font-size:18px;
                    color:#464343;
                    text-indent:2em;
                    line-height:38px;
                }
                .-hr-list{
                    padding:30px 0;
                    span{
                        display:block;
                        line-height:50px;
                        font-size:18px;
                        color:#464343;
                        em{
                            font-style:normal;
                            font-weight:bold;
                            color:#212121
                        }
                    }
                }
            }
            &.-grey{
                background-color:#f3f4f8;
            }
            .-hr-list-2{
                display:flex;
                justify-content:space-around;
                .-hr-pr-content{
                    width:240px;
                    flext:0 0 auto;
                    padding:20px 0;
                    .-bg{
                        width:200px;
                        height:200px;
                        border-radius:200px;
                        color:#fff;
                        text-align:center;
                        font-size:30px;
                        line-height:40px;
                        display:flex;
                        align-items:center;
                        justify-content:center;
                        font-weight:bold;
                        margin:0 auto;
                        &.-bg1{
                            background-image:url('~@/images/hr/bg1.png');
                        }
                        &.-bg2{
                            background-image:url('~@/images/hr/bg2.png');
                        }
                        &.-bg3{
                            background-image:url('~@/images/hr/bg3.png');
                        }
                    }
                    .-p{
                        padding:20px 0;
                        color:#464343;
                        font-size:18px;
                        line-height:40px;
                    }
                }
            }
        }
        .-jd-info{
            padding:30px 0 20px 0;
            + .-jd-info{
                border-top:1px dashed #ccc;
            }
            .-need-name{
                color: #131415;
                font-size: 24px;
                font-weight: 500;
                padding-left:20px;
                border-left:3px solid #325ebf;
                height:28px;
                line-height:28px;
                margin-bottom:18px;
            }
            .-need-info{
                p{
                    line-height:24px;
                    font-size:14px;
                    margin:10px 0;;
                }
            }
        }
    }
</style>