
export const progressInfo = [

    {
        time: 2023,
        info: [
            '福信富通承建的国家北斗重大专项亮相第六届数字中国建设峰会',
            '福信富通荣获福建省科学技术进步奖二等奖',
            '福信富通“北斗物联中枢云”入选省信息技术应用创新典型案例',
            '福信富通北斗智慧海洋项目荣获2023数字中国创新大赛全国第四名',
            '福信富通亮相CCTV大型纪录片《大国基石》之《北斗巡天》'
        ]
    },
    {
        time: 2022,
        info: [
            '亮相第五届数字中国建设峰会',
            '开发的“基于AI识别的北斗主动安全预警系统及终端应用”入选2022年度数字技术创新应用场景',
            '被工业和信息化部评为专精特新“小巨人”企业',
            '福董事长李勇被授予“福建省优秀企业家”荣誉称号',
            '被评为2022年度福建省重点上市后备企业'
        ]
    },
    {
        time: 2021,
        info: [
            '被评为2021年度福建省数字经济领域未来“独角兽”创新企业',
            '入选福建省资本市场服务网络强省重点网信企业名单',
            '被授予鼓楼区“功勋企业”',
            '被评为2021年福建省科技小巨人',
            '亮相第十二届中国卫星导航年会',
            '亮相第四届数字中国建设峰会',
        ]
    },
    {
        time: 2020,
        info: [
            '北斗卫星导航系统总设计师杨长风院士一行莅临福信富通参观指导',
            '“北斗塔杆减灾预警及应急云调度系统”获得“福建省测绘地理信息科技进步奖二等奖”',
            '亮相第十一届中国卫星导航年会',
            '“主动安全视频解决方案”、”网络货运平台解决方案”荣获2020中国商用车车联网解决方案“金手指”奖',
            '“被评为2020年度福建省数字经济领域 “瞪羚”创新企业',
            '“北斗车联网项目部被授予“福建省工人先锋号”荣誉称号'
        ]
    },
    {
        time: 2019,
        info: [
            '北斗•车联网”项目荣获2019年“创客中国”中小企业创新创业大赛福建省一等奖、全国总决赛三等奖',
            '荣获“物联中国”路演大赛全国总决赛一等奖',
            '当选福建省军民融合发展促进会理事单位',
            '北斗开放实验室第五次工作会在北京卫星导航中心召开，福信富通入选北斗开放实验室主建单位',
            '亮相第十届中国卫星导航年会，作为泉州分实验室牵头承建单位，福信富通出席北斗开放实验室国际分实验室共建签约仪式',
            '研发的ASCS主动安全智能防控管理系统荣获“2019中国汽车主动安全智能防控十佳平台”',
        ]
    },
    {
        time: 2018,
        info: [
            `研发的基于北斗高精度定位及大数据平台的电动汽车安全网联系统荣获首届智能电动汽车领域科技二等奖`,
            `承建的北斗数字交通平台亮相数字中国建设峰会`,
            `被评为2018年福建省重点上市后备企业`,
            `荣膺“2018中国车联网十佳创新企业”，公司董事长、总经理李勇被评为“2018中国车联网十大创新人物”`,
            `成功入选中国移动物联网联盟第二批成员单位`
        ]
    },
    {
        time: 2017,
        info: [
            `获得专家工作站授牌`,
            `研发建设的“北斗围栏”系统助力金砖国家领导人第九次会晤期间的交通安全保障`,
            `北斗（泉州）开放实验室携北斗新品模块，亮相第八届中国卫星导航学术年会`,
            `中标福建北斗系统民用示范项目`

        ]
    },
    {
        time: 2016,
        info: [
            `发起建立北斗卫星导航泉州开放实验室，中央电视台新闻联播报道`,
            `福信富通、海峡北斗加入福建省（海西）卫星导航产业技术创新战略联盟`,
            `福信富通、海峡北斗研究院成功加入中国电子商会智能电动汽车专委会，福信富通成为专委会副理事长单位，海峡北斗研究院成为专委会理事单位，福信富通董事长李勇成为专委会副理事长`

        ]
    },
    {
        time: 2015,
        info: [
            '卫星导航仿真与测试开放实验室、国防科技大学泉州工业技术研究中心、福建省福信富通网络科技股份有限公司三方就泉州开放实验室建设达成战略合作协议，福信富通成为主体建设单位'
        ]
    },
    {
        time: 2014,
        info: [
            '被认定软件企业，获得软件企业认定证书'
        ]
    },
    {
        time: 2013,
        info: [
            `福信富通与中国移动、中国联通、中国电信签订⻋联⽹战略合作协议`,
            `“车联网与智能交通信息服务关键技术研究与应用”项目成功通过验收`

        ]
    },
    {
        time: 2012,
        info: [
            '福建省信息化局授予福信富通软件企业',
        ]
    },
    {
        time: 2010,
        info: [
            '2010年9月17日，福信富通（福建）网络科技有限公司成立'
        ]
    },
].reverse();