<template>
     <div class="-about-container">
        <div class="-top-banner"></div>
        <div class="-about-tabs">
            <div class="-about-tabs-content">
                <h6 :class="{'-active':activeName === subMenu.link}" v-for="(subMenu,i) in aboutusMenu.subTitle"
                    @click="linkTo(subMenu.link as string)"
                    v-text="subMenu.title" :key="i"></h6>
            </div>
        </div>
        <div class="-about-detail" id="main"> 
            <div class="-about-detail-content">
                <h5>企业概况</h5>
                <div class="-detail-inner-1">
                    <div class="-l">
                        <h6>专注北斗创新应用  构建北斗时空数字底座</h6>
                        <p>
                            <b>福信富通科技股份有限公司</b>（简称“福信富通”）成立于2010年9月，围绕北斗卫星相关核心技术进行研发，为数字城市空天地海一体化提供时空、位置服务解决方案。围绕数字交通、数字海洋、数字能源、数字城市等核心业务方向，为用户提供卓越的北斗技术解决方案及产品服务。
                        </p>
                        <p>
                            福信富通将顺应用户需求升级、多技术融合加速与商业模式变革等趋势，加速北斗关键技术创新研发，推动业务板块联动发展，全力构建数字时代的“北斗时空数字底座”，为数字中国建设添砖加瓦，助力北斗“一带一路”建设。
                        </p>
                    </div>
                    <div class="-r">
                        <svg class="-left"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            width="23px" height="22px">
                            <path fill-rule="evenodd"  fill="rgb(134, 177, 255)"
                            d="M2.500,2.187 L2.500,21.875 L0.406,21.875 L0.406,2.187 L0.406,0.094 L2.500,0.094 L22.125,0.094 L22.125,2.187 L2.500,2.187 Z"/>
                        </svg>
                        <svg class="-bottom"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            width="23px" height="23px">
                            <path fill-rule="evenodd"  fill="rgb(134, 177, 255)"
                            d="M20.562,22.156 L0.875,22.156 L0.875,20.031 L20.562,20.031 L20.562,0.375 L22.656,0.375 L22.656,20.031 L22.656,22.156 L20.562,22.156 Z"/>
                        </svg>
                        <div class="-video">
                            <img src="~@/images/about_1.png" />
                            <svg class="-play"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                width="45px" height="45px">
                                <path fill-rule="evenodd" 
                                d="M22.658,44.508 C10.450,44.508 0.554,34.612 0.554,22.402 C0.554,10.195 10.450,0.298 22.658,0.298 C34.867,0.298 44.763,10.195 44.763,22.402 C44.763,34.612 34.867,44.508 22.658,44.508 ZM22.658,3.975 C12.481,3.975 4.230,12.225 4.230,22.403 C4.230,32.581 12.481,40.832 22.658,40.832 C32.836,40.832 41.087,32.581 41.087,22.403 C41.087,12.225 32.836,3.975 22.658,3.975 ZM32.552,25.021 L22.853,32.068 C22.500,32.407 22.029,32.579 21.556,32.587 C21.547,32.588 21.538,32.590 21.530,32.590 C20.655,32.595 19.789,32.034 19.639,30.930 C19.626,30.847 19.618,30.764 19.616,30.680 C19.615,30.650 19.608,30.625 19.608,30.594 L19.608,14.212 C19.608,14.181 19.615,14.156 19.616,14.126 C19.618,14.042 19.626,13.960 19.639,13.878 C19.788,12.770 20.657,12.210 21.533,12.216 C21.535,12.216 21.537,12.217 21.539,12.217 C22.019,12.222 22.497,12.395 22.855,12.739 L32.523,19.766 C34.805,21.423 34.919,23.300 32.552,25.021 ZM23.445,17.910 L23.445,26.897 L29.627,22.403 L23.445,17.910 ZM13.361,30.594 L13.361,14.212 C13.361,11.550 17.198,11.535 17.198,14.257 L17.198,30.548 C17.198,33.271 13.361,33.255 13.361,30.594 Z"/>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="-about-detail -grey" id="architecture">
            <div class="-about-detail-content">
                <h5>公司架构</h5>
                <div class="-detail-inner-2">
                    
                </div>
            </div>
        </div>
        <div class="-about-detail">
            <div class="-about-detail-content">
                <h5>发展历程</h5>
                <div class="-detail-inner-3">
                    <div class="-progress-detail">
                        <div class="-year" v-text="currentProgress.time"></div>
                        <div class="-p-detail-p">
                            <span v-for="(item,i) in currentProgress.info" v-text="item" :key="i"></span>
                        </div>
                    </div>
                    <div class="-progress-time">
                        <div class="-line"></div>
                        <div class="-time">
                            <svg 
                                v-show="timeRight !== timeMax"
                                @click="preYear"
                                class="-left"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                width="18px" height="17px">
                                <path fill-rule="evenodd"  f
                                d="M17.1000,8.1000 L3.816,8.1000 L8.656,13.948 C9.115,14.417 9.115,15.178 8.656,15.648 C8.196,16.117 7.452,16.117 6.993,15.648 L0.343,8.849 C-0.116,8.379 -0.116,7.618 0.343,7.149 L6.993,0.350 C7.452,-0.119 8.196,-0.119 8.656,0.350 C9.115,0.819 9.115,1.580 8.656,2.050 L3.815,6.1000 L17.1000,6.1000 L17.1000,8.1000 Z"/>
                            </svg>
                            <div class="-time-content"  :style="{width:`${timeWidth}px`,right:`${timeRight}px`}">
                                <div v-for="(_p,i) in progressInfo" :key="i" class="-time-info" @click="gotoYear(i)"
                                    :class="{'-active':currentProgress.time === _p.time}">
                                    <span v-text="_p.time"></span>
                                </div>
                            </div>
                            <svg 
                                v-show="timeRight !== 0"
                                @click="nextYear"
                                class="-right"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                width="19px" height="16px">
                                <path fill-rule="evenodd"  
                                d="M17.656,8.832 L11.007,15.617 C10.548,16.085 9.803,16.085 9.344,15.617 C8.885,15.149 8.885,14.389 9.344,13.920 L14.197,8.968 L-0.000,8.968 L-0.000,6.1000 L14.199,6.1000 L9.344,2.046 C8.885,1.577 8.885,0.817 9.344,0.350 C9.803,-0.119 10.548,-0.119 11.007,0.350 L17.656,7.135 C18.115,7.603 18.115,8.363 17.656,8.832 Z"/>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="-about-detail -grey">
            <div class="-about-detail-content">
                <h5>资质荣誉</h5>
                <div class="-detail-inner-4">
                    <svg 
                        v-show="honorsLeft !== 0"
                        @click="preHonor"
                        class="-left"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        width="38px" height="38px">
                        <path fill-rule="evenodd"  
                        d="M20.656,11.050 L14.837,16.999 L20.656,22.948 C21.115,23.417 21.115,24.179 20.656,24.648 C20.197,25.117 19.452,25.117 18.993,24.648 L12.343,17.849 C11.884,17.380 11.884,16.619 12.343,16.149 L18.993,9.350 C19.452,8.881 20.197,8.881 20.656,9.350 C21.115,9.819 21.115,10.581 20.656,11.050 Z"/>
                    </svg>
                    <div class="-honors-item-container" :style="{width:`${honorsWidth}px`,left:`${honorsLeft}px`}">
                        <div class="-honors-item-content" v-for="(_info,k) in honorsGroup" :key="k" >
                            <div class="-honors-item" v-for="(item,i) in _info" :key="i">
                                <div class="-honors-cover">
                                    <img :src="item.pic" />
                                </div>
                                <span v-text="item.title"></span>
                            </div>
                        </div>
                    </div>
                    <svg 
                        v-show="honorsLeft !== honorMax"
                        @click="nextHonor"
                        class="-right"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        width="38px" height="38px">
                        <path fill-rule="evenodd" 
                        d="M21.656,17.849 L15.007,24.648 C14.548,25.117 13.803,25.117 13.344,24.648 C12.885,24.179 12.885,23.417 13.344,22.948 L19.163,16.999 L13.344,11.050 C12.885,10.581 12.885,9.819 13.344,9.350 C13.803,8.881 14.548,8.881 15.007,9.350 L21.656,16.149 C22.115,16.619 22.115,17.380 21.656,17.849 Z"/>
                    </svg>
                </div>
            </div>
        </div>
        <div class="-about-detail">
            <div class="-about-detail-content">
                <h5>知识产权</h5>
                <div class="-detail-inner-5">
                    <svg 
                        v-show="ipsLeft !== 0"
                        @click="preIp"
                        class="-left"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        width="38px" height="38px">
                        <path fill-rule="evenodd"  
                        d="M20.656,11.050 L14.837,16.999 L20.656,22.948 C21.115,23.417 21.115,24.179 20.656,24.648 C20.197,25.117 19.452,25.117 18.993,24.648 L12.343,17.849 C11.884,17.380 11.884,16.619 12.343,16.149 L18.993,9.350 C19.452,8.881 20.197,8.881 20.656,9.350 C21.115,9.819 21.115,10.581 20.656,11.050 Z"/>
                    </svg>
                    <div class="-ip-item-container" :style="{width:`${ipsWidth}px`,left:`${ipsLeft}px`}">
                        <div class="-ip-item-content" v-for="(_info,k) in ipGroup" :key="k" >
                            <div class="-ip-item" v-for="(item,i) in _info" :key="i">
                                <div class="-ip-cover">
                                    <img :src="item.pic" :title="item.title"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <svg 
                        v-show="ipsLeft !== ipMax"
                        @click="nextIp"
                        class="-right"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        width="38px" height="38px">
                        <path fill-rule="evenodd" 
                        d="M21.656,17.849 L15.007,24.648 C14.548,25.117 13.803,25.117 13.344,24.648 C12.885,24.179 12.885,23.417 13.344,22.948 L19.163,16.999 L13.344,11.050 C12.885,10.581 12.885,9.819 13.344,9.350 C13.803,8.881 14.548,8.881 15.007,9.350 L21.656,16.149 C22.115,16.619 22.115,17.380 21.656,17.849 Z"/>
                    </svg>
                </div>
            </div>
        </div>
        <div class="-about-detail -grey">
            <div class="-about-detail-content">
                <h5>社会责任</h5>
                <div class="-detail-inner-6">
                    <div class="-csr-l"></div>
                    <div class="-csr-r">
                        <p>公司自成立以来，始终致力于自主研发、科技创新，紧密围绕北斗相关核心技术进行平台及产品的设计、开发与应用，提供以位置服务为中心的行业整体解决方案。</p>
                        <p>2019年，福信富通入选国家北斗开放实验室主建单位，成为国家北斗开放实验主建单位中的一家民营企业。紧抓北斗三号全球组网成功的重要契机，围绕北斗“一带一路”发展战略，公司承建了北斗三号组网成功后全国首批省级应用示范项目。</p>
                        <p>随着时代的发展和科技的进步，福信富通正着力构建“北斗时空数据底座”，推动北斗与各项技术的融合发展，推进北斗卫星导航技术在更深层次、更广领域服务于社会民生。</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
    import { useRoute,useRouter } from 'vue-router';
    import { computed,ref,Ref ,watch,onMounted,onUnmounted} from 'vue';
    import {aboutusMenu} from './../../dic/menuInfo';
    import {progressInfo} from './../../dic/progressInfo';
    import {honorsInfo} from './../../dic/honorsInfo'
    import {intellectualInfo} from './../../dic/intellectualInfo';
    import {scrollTop} from './../../dic/utils';
   

    const singleScreenWidth = 1200;

    let honorsGroup:any[] = [];
    let _honorsLength = honorsInfo.length;
    const honorsInfoCopy = [...honorsInfo];
    for(let i = 0;i<_honorsLength;i=i+6){
        honorsGroup.push(honorsInfoCopy.splice(0,Math.min(honorsInfoCopy.length,6)));
    }
    const honorsWidth = honorsGroup.length * singleScreenWidth;
    const honorsLeft:Ref<number> = ref(0);
    const honorMax = -(honorsWidth - singleScreenWidth);
    const preHonor = ()=>{
        honorsLeft.value = Math.min(0,honorsLeft.value + singleScreenWidth);
    }
    const nextHonor = ()=>{
        honorsLeft.value = Math.max(honorMax,honorsLeft.value - singleScreenWidth);
    
    }

    let ipGroup:any[] = [];
    let _ipsLength = intellectualInfo.length; 
    const intellectualInfoCopy = [...intellectualInfo];
    for(let i = 0;i<_ipsLength;i=i+8){
        ipGroup.push(intellectualInfoCopy.splice(0,Math.min(intellectualInfoCopy.length,8)));
    }
    const ipsWidth = ipGroup.length * singleScreenWidth;
    const ipsLeft:Ref<number> = ref(0);
    const ipMax = -(ipsWidth - singleScreenWidth);
    const preIp = ()=>{
        ipsLeft.value = Math.min(0,ipsLeft.value + singleScreenWidth);
    }
    const nextIp = ()=>{
        ipsLeft.value = Math.max(ipMax,ipsLeft.value - singleScreenWidth);
    
    }

    let move = false;
    let clickEVent = false;
    let timeOut:any;
    const event = ()=>{
        if(clickEVent){
            return;
        }
        clearTimeout(timeOut);        
        let a = window.scrollY;
        if(a < scrollInfo.architecture){
            router.push(`${aboutusMenu.parentLink}/${(aboutusMenu.subTitle as Array<any>)[0].link}`);
        }else if(a < scrollInfo.history){
            router.push(`${aboutusMenu.parentLink}/${(aboutusMenu.subTitle as Array<any>)[1].link}`);
        }else if(a < scrollInfo.honors){
            router.push(`${aboutusMenu.parentLink}/${(aboutusMenu.subTitle as Array<any>)[2].link}`);
        }else if(a < scrollInfo.intellectual){
            router.push(`${aboutusMenu.parentLink}/${(aboutusMenu.subTitle as Array<any>)[3].link}`);
        }else if(a < scrollInfo.csr){
            router.push(`${aboutusMenu.parentLink}/${(aboutusMenu.subTitle as Array<any>)[4].link}`);
        }else {
            router.push(`${aboutusMenu.parentLink}/${(aboutusMenu.subTitle as Array<any>)[5].link}`);
        }
        move = true;
        setTimeout(()=>{
            move = false;
        },100);
    }
    onMounted(()=>{
        window.addEventListener('scroll',event);
    });
    onUnmounted(()=>{
        window.removeEventListener('scroll',event);
    })

    const route = useRoute();
    const activeName = computed(()=>{
        return route.params.type;
    });

    const scrollInfo:{[key:string]:number} = {
        main:600,
        architecture:1200,
        history:1800,
        honors:2400,
        intellectual:3150,
        csr:3980,
    } 
    watch(activeName,(value)=>{
        if(value !== 'main' && !move){ 
            scrollTop(scrollInfo[value as keyof typeof scrollInfo] -10);
        }
    },{
        immediate:true
    })
    
    const router = useRouter();
    const linkTo = (link:string)=>{
        router.push(`${aboutusMenu.parentLink}/${link}`);
        clickEVent = true;
        setTimeout(()=>{
            clickEVent = false; 
        },500);
        scrollTop(scrollInfo[link as keyof typeof scrollInfo] - 5);  
    }

     
    const currentProgress:Ref<{time:number,info:string[]}> = ref(progressInfo[progressInfo.length-1])

    const singleWidth = 176;
    const timeWidth = progressInfo.length * singleWidth;

    const timeRight:Ref<number> = ref(0);
    
    const timeMax = -(timeWidth - singleScreenWidth);

    const gotoYear = (index:number)=>{
        currentProgress.value = progressInfo[index];
    }
    const preYear = ()=>{
        timeRight.value = Math.max(timeMax,timeRight.value - singleWidth);
    }
    const nextYear = ()=>{
        timeRight.value = Math.min(0,timeRight.value + singleWidth);
    
    }
    

</script>
<style lang="scss">

    $mainColor:#325ebf;        
    .-about-container{
        .-top-banner{
            width:100%;
            height:590px;
            background-position: center center;
            background-image:url('~@/images/about_banner.png');
        }
        .-about-tabs{
            width:100%;
            min-width: 1200px;
            border-bottom:5px solid #dbdbdb;
            position: -webkit-sticky;
            position: sticky; 
            top: 0;
            background-color: #fff;
            z-index: 9;
            .-about-tabs-content{
                width:1200px;
                height:84px;
                display: flex;
                justify-content: flex-start;
                margin:0 auto;
                h6{
                    font-size: 20px;
                    font-weight: normal;
                    width:170px;
                    height:84px;
                    text-align: center;
                    line-height: 84px;
                    color:#464343;
                    position: relative;
                    cursor: pointer;
                    &:hover{
                        color:$mainColor;
                    }
                    &.-active{
                        color:$mainColor;
                        &:after{
                            content:'';
                            position: absolute;
                            height:5px;
                            width:100%;
                            bottom:-5px;
                            left:0;
                            background-color: $mainColor;
                        }
                    }
                }
            }
        }
        .-about-detail{
            width:100%;
            min-width: 1200px;
            &.-grey{
                background-color: #f3f4f8;
            }
            .-about-detail-content{
                width:1200px;
                margin:0 auto;
                padding:15px 0;
                user-select: none;
                h5{
                    font-size: 30px;
                    font-weight: normal;
                    line-height: 100px;
                }
                .-detail-inner-1{
                    padding-top:30px;
                    padding-bottom:15px;
                    display: flex;
                    width:100%;
                    align-items: flex-end; 
                    .-l{
                        margin-right:30px;
                        h6{
                            font-size: 28px;
                            font-weight: normal;
                        }
                        p{
                            line-height: 35px;
                            font-size: 18px;
                            color:#464343;
                            text-indent: 2em;
                            b{
                                font-weight: bold;
                                color:#212121;
                                font-style: normal;
                            }
                        }
                    }
                    .-r{
                        position: relative;
                        width:560px;
                        height:390px;
                        flex:0 0 auto;
                        svg{
                            position: absolute;
                            &.-left{
                                top:0;
                                left:0;
                            }
                            &.-bottom{
                                bottom:0;
                                right:0;
                            }
                        }
                        .-video{
                            position: absolute;
                            width:530px;
                            height:370px;
                            top:8px;
                            left:12px;
                            .-play{
                                position: absolute;
                                width:45px;
                                height:45px;
                                top:50%;
                                left:50%;
                                transform: translate(-50%,-50%);
                                fill:#ffffff;
                                cursor: pointer;
                                &:hover{
                                    fill:$mainColor;
                                }
                            }
                        }
                    }
                }
                .-detail-inner-2{
                    height:500px;
                    width:100%;
                    background-repeat: no-repeat;
                    background-position: center center;
                    background-image:url('~@/images/group.jpg');
                }
                .-detail-inner-3{
                    .-progress-detail{
                        height:380px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        .-year{
                            font-size: 50px;
                            color:#464343;
                            font-weight: bold;
                            width:180px;
                        }
                        .-p-detail-p{
                            width:680px;
                            span{
                                // height:40px;
                                line-height: 36px;
                                padding:2px 0;
                                color:#464343;
                                display: block;
                                font-size: 18px;;
                            }
                        }
                    }
                    .-progress-time{
                        position: relative;
                        .-line{
                            width:100%;
                            height:2px;
                            background-color: #727171;
                            position: absolute;
                            top:9px;
                        }
                        .-time{
                            width:100%;
                            overflow: hidden;
                            position: relative;
                            height: 80px;;
                            .-time-content{
                                display: flex;
                                position: absolute;
                                top:0;
                                right:0;
                                transition: right 0.2s linear;
                            }
                            .-time-info{
                                width:176px;
                                text-align: center;
                                color:#757474;
                                font-size: 24px;
                                height:80px;
                                line-height: 80px;
                                position: relative;
                                padding-top:8px;
                                cursor: pointer;
                                &::before{
                                    content:'';
                                    background-color: #fff;;
                                    width:20px;
                                    height:20px;
                                    border-radius: 10px;
                                    box-sizing: border-box;
                                    border:1px solid #757474;
                                    position: absolute;
                                    top:0;
                                    left:50%;
                                    transform: translate(-50%,0%);
                                }
                                &::after{
                                    content:'';
                                    width:10px;
                                    height:10px;
                                    border-radius: 5px;
                                    background-color: #757474;
                                    position: absolute;
                                    top:0;
                                    left:50%;
                                    transform: translate(-50%,50%);
                                }
                                &.-active{
                                    color:$mainColor;
                                    &::before{
                                        border-color: $mainColor;
                                    }
                                    &::after{
                                        background: $mainColor;
                                    }
                                }
                            }
                            svg{
                                position: absolute;
                                bottom:24px;
                                fill:#727272;
                                cursor: pointer;
                                z-index: 2;
                                &.-left{
                                    left:0px;
                                }
                                &.-right{
                                    right:0px;
                                }
                                &:hover{
                                    fill:$mainColor;
                                }
                            }
                        }
                    }
                }
                .-detail-inner-4{
                    position: relative;
                    overflow: hidden;
                    height:616px;
                    svg{
                        position: absolute;
                        top:50%;
                        transform: translateY(-110%);
                        fill:#727272;
                        border:2px solid #727272;
                        border-radius: 50%;
                        box-sizing: border-box;
                        z-index: 9;
                        &:hover{
                            fill:$mainColor;
                            border-color: $mainColor;
                        }
                        &.-left{
                            left:0;
                        }
                        &.-right{
                            right:0;
                        }
                    }
                    .-honors-item-container{
                        position: absolute;
                        left:0;
                        top:0;
                        display: flex;
                        flex-wrap: nowrap;
                        height:100%;
                        transition: left 0.2s linear;
                        .-honors-item-content{
                            width:1200px;
                            display: flex;
                            flex-wrap: wrap;
                            justify-content: space-around;
                            height:100%;
                            padding:0 20px;
                            box-sizing: border-box;
                        }
                        .-honors-item{
                            .-honors-cover{
                                background-color: #fff;
                                width:328px;
                                height:228px;
                                box-sizing: border-box;
                                padding:14px 14px;
                                img{
                                    width:300px;
                                    height:200px;
                                }
                            }
                            span{
                                font-size: 18px;
                                color:#464343;
                                text-align: center;
                                display: block;
                                height:60px;
                                line-height: 60px;
                                padding-bottom: 20px;;
                            }
                        }
                    }
                }
                .-detail-inner-5{
                    position: relative;
                    overflow: hidden;
                    height:700px;
                    svg{
                        position: absolute;
                        top:50%;
                        transform: translateY(-60%);
                        fill:#727272;
                        border:2px solid #727272;
                        border-radius: 50%;
                        box-sizing: border-box;
                        z-index: 9;
                        &:hover{
                            fill:$mainColor;
                            border-color: $mainColor;
                        }
                        &.-left{
                            left:0;
                        }
                        &.-right{
                            right:0;
                        }
                    }
                    .-ip-item-container{
                        position: absolute;
                        left:0;
                        top:0;
                        display: flex;
                        flex-wrap: nowrap;
                        height:100%;
                        transition: left 0.2s linear;
                        .-ip-item-content{
                            width:1200px;
                            display: flex;
                            flex-wrap: wrap;
                            justify-content: space-around;
                            height:100%;
                            padding:0 20px;
                            box-sizing: border-box;
                        }
                        .-ip-item{
                            .-ip-cover{
                                background-color: #fff;
                                width:234px;
                                height:319px;
                                padding: 10px 0;
                                img{
                                    width:234px;
                                    height:319px;
                                }
                            }
                        }
                    }
                }
                .-detail-inner-6{
                    display: flex;
                    padding:0 0 25px 0;
                    .-csr-l{
                        flex:0 0 auto;
                        width:640px;
                        height:380px;
                        background-repeat: no-repeat;
                        background-position: center center;
                        background-image:url('~@/images/about_2.png');
                    }
                    .-csr-r{
                        margin-left:30px;
                        font-size: 16px;
                        color:#464343;
                        p{
                            text-indent: 2em;
                            padding:0 0 12px 0;
                            margin:0;
                            line-height: 32px;
                        }
                    }
                }
            }
        }
    }
</style>