<template>
    <div class="-news-detail-container">
        <div class="-top-banner"></div>
        <div class="-news-detail">
            <div class="-news-info">
                <h2 v-text="infoData.title"></h2>
                <span class="-time">发布时间：<em v-text="infoData.time"></em></span>
                <div class="-info" v-html="infoData.content">

                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import moment from 'moment';
import { demoDao } from '@/dao/demoDao';
import { useRoute, useRouter } from 'vue-router';
import { computed, ref, Ref } from 'vue';

import './editor.scss';


const infoData: Ref<any> = ref({});


const getList = (id: number) => {
    demoDao.getNewDetail(id).then((data: any) => {
        console.log(data);
        let info: any = {};
        info.title = data.title.rendered.replace(/<[^<>]+>/g, "").replace(/\r|\n/g, "");
        info.time = moment(data.date).format('YYYY-MM-DD');
        info.content = data.content.rendered;
        infoData.value = info;
    })
}

const route = useRoute();
const newsId = computed(() => {
    return route.params.type;
});

getList(newsId.value);
// const router = useRouter();
// const linkTo = (link:string)=>{
//     router.push(`${newsMenu.parentLink}/${link}`);
//     getList(newsType[link]);
// }


</script>
<style lang="scss">
$mainColor: #e7871d;

.-news-detail-container {
    .-top-banner {
        width: 100%;
        height: 110px;
        background-color: #222;
    }

    .-news-detail {
        width: 100%;

        .-news-info {
            width: 1200px;
            margin: 0 auto;
            padding: 30px 0;

            h2 {
                line-height: 80px;
                height: 80px;
                font-size: 28px;
                font-weight: bold;
            }

            .-time {
                color: #9ba4b5;
                font-size: 14px;
                margin-bottom: 20px;
                display: block;

                em {
                    font-style: normal;
                }
            }

            .-info {
                padding: 30px 0;
                border-top: 1px dashed #ccc;
                //padding: 0 100px;
            }
        }
    }
}
</style>