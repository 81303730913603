

import {baseDao} from './baseDao';

let demoDaoInstance:demoDaoClass;
class demoDaoClass extends baseDao{
    constructor(){
        if(demoDaoInstance){
            return demoDaoInstance
        }
        super();
    }
    public getNewList(catType:number,page:number):Promise<any>{
        // eslint-disable-next-line 
        //@ts-ignore
        return this.get(`${window.address}/wp-json/wp/v2/posts?categories=${catType}&page=${page}&per_page=10`);
        
    }
    public getHrList():Promise<any>{
        // eslint-disable-next-line 
        //@ts-ignore
        return this.get(`${window.address}/wp-json/wp/v2/posts?categories=6&page=1&per_page=100`);
        
    }
    public getNewDetail(id:number){
        // eslint-disable-next-line 
        //@ts-ignore
        return this.get(`${window.address}/wp-json/wp/v2/posts/${id}`);
    }
    public getListCount(type:number){
        // eslint-disable-next-line 
        //@ts-ignore
        return this.get(`${window.address}/wp-json/wp/v2/categories/${type}`);
    }
}
export const demoDao = new demoDaoClass();
// export default new demoDaoClass();