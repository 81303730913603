<template>
     <div class="-news-container">
        <div class="-top-banner"></div>
        <div class="-news-tabs">
            <div class="-news-tabs-content">
                <h6 :class="{'-active':activeName === subMenu.link}" v-for="(subMenu,i) in newsMenu.subTitle"
                    @click="linkTo(subMenu.link as string)"
                    v-text="subMenu.title" :key="i"></h6>
            </div>
        </div>
        <div class="-news-content">
            <div class="-news-top-content" v-if="topData.d">
                <div class="-news-top-inner">
                    <img :src="topData.cover" />
                    <div class="-p">
                        <div class="-time">
                            <em v-text="topData.d"></em>
                            <span>/</span>
                            <span v-text="topData.m"></span>
                            <span class="-year" v-text="topData.y"></span>
                        </div>
                        <div class="-title" v-html="topData.titleTxt" @click="toDetail(topData.id)">
                        </div>
                        <div class="-info" v-html="topData.infoTxt"></div>
                        <div class="-more">
                            <a @click="toDetail(topData.id)">
                                了解更多
                                <svg 
                                xmlns="http://www.w3.org/2000/svg"
                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                width="66px" height="13px">
                                <path fill-rule="evenodd"   stroke-width="2px" stroke-linecap="butt" stroke-linejoin="miter" fill="none"
                                d="M0.1000,8.1000 L58.1000,8.1000 L52.1000,2.1000 "/>
                                </svg>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="-news-list-content">
                <div class="-news-list-item" v-for="(item ,i) in commonList" :key="i">
                    <div class="-time">
                        <span v-text="item.d"></span>
                        <div>
                            {{`${item.y}.${item.m}`}}
                        </div>
                    </div>
                    <div class="-p" @click="toDetail(item.id)">
                        <div class="-title" v-text="item.titleTxt">
                        </div>
                        <div class="-info" v-html="item.infoTxt">
                        </div>
                    </div>
                </div>
                <div class="-pager" v-show="pageCount">
                    <v-pagination
                        v-model="page"
                        :pages="pageCount"
                        :range-size="1"
                        active-color="#DCEDFF"
                        :hide-first-button="true"
                        :hide-last-button="true"
                        @update:modelValue="updateHandler"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>

    import moment from 'moment';
    import { demoDao } from '@/dao/demoDao';
    import { useRoute,useRouter } from 'vue-router';
    import { computed,ref,Ref } from 'vue';
    import {newsMenu,newsType} from '../../dic/menuInfo';

    import VPagination from '@hennge/vue3-pagination';
    import "@hennge/vue3-pagination/dist/vue3-pagination.css";
    // import "./editor.css";

    let allCount = 0;
    const perPager = 10;
    const page = ref(1);
    const pageCount = ref(0);
    const updateHandler = ()=>{
        console.log(page.value);
        getList(newsType[activeName.value]);
    }

    const getList = (type:number)=>{
        demoDao.getNewList(type,page.value).then(data=>{
            console.log(data);
            data.forEach((item:any) => {
                const _i = moment(item.date);
                item.y = _i.year();
                item.m = _i.month()+1;
                item.d = _i.date();
                let _cover = item.content.rendered.match(/<img [^>]*src=['"]([^'"]+)[^>]*>/);
                if(_cover){
                    item.cover = _cover[1];
                }
                item.titleTxt = item.title.rendered.replace(/<[^<>]+>/g,"");
                item.infoTxt = item.excerpt.rendered.replace(/<[^<>]+>/g,"").replace(/\r|\n/g,"");
            });
            if(data[0].cover){
                topData.value = data.shift();
            }else{
                topData.value = {};
            }
            commonList.value = data;
        })
    }

    const getCount = (type:number)=>{
        demoDao.getListCount(type).then((data:any)=>{
            allCount = data.count;
            pageCount.value = Math.ceil(allCount/perPager);
        })
    }


    const topData:Ref<any> = ref({});
    const commonList:Ref<any[]> = ref([]);

    const route = useRoute();
    const activeName = computed(()=>{
        return route.params.type;
    });

    getList(newsType[activeName.value]);
    getCount(newsType[activeName.value])
    const router = useRouter();
    const linkTo = (link:string)=>{
        router.push(`${newsMenu.parentLink}/${link}`);
        page.value = 1;
        getList(newsType[link]);
        getCount(newsType[activeName.value]);
    }

    const toDetail = (id:string)=>{
        window.open(`/index.html#newsdetail/${id}`,'_blank');
        // router.push(`/newsdetail/${id}`);
    }
    

</script>
<style lang="scss">

    $mainColor:#325ebf;       
    .-news-container{
        .-top-banner{
            width:100%;
            height:434px;
            background-position: center center;
            background-image:url('~@/images/news_banner.png');
        }
        .-news-tabs{
            width:100%;
            min-width: 1200px;
            border-bottom:5px solid #dbdbdb;
            position: -webkit-sticky;
            position: sticky; 
            top: 0; 
            background-color: #fff;
            z-index: 19;
            .-news-tabs-content{
                width:1200px;
                height:84px;
                display: flex;
                justify-content: flex-start;
                margin:0 auto;
                h6{
                    font-size: 20px;
                    font-weight: normal;
                    width:170px;
                    height:84px;
                    text-align: center;
                    line-height: 84px;
                    color:#464343;
                    position: relative;
                    cursor: pointer;
                    &:hover{
                        color:$mainColor;
                    }
                    &.-active{
                        color:$mainColor;
                        &:after{
                            content:'';
                            position: absolute;
                            height:5px;
                            width:100%;
                            bottom:-5px;
                            left:0;
                            background-color: $mainColor;
                        }
                    }
                }
            }
        }
        .-news-content{
            width:100%;
            .-news-top-content{
                background-color: #f8f8f8;
                .-news-top-inner{
                    width:1200px;
                    margin:0 auto;
                    padding:60px 0;
                    display: flex;
                    > img{
                        flex:0 0 auto;
                        width:600px;
                        height:400px;
                    }
                }
                .-p{
                    margin-left:46px;
                    color:#464343;
                    position: relative;
                    .-time{
                        display: flex;
                        align-items: baseline;
                        font-size: 28px;
                        height:50px;
                        padding:30px 0;
                        em{
                            font-size: 54px;
                            color:#5a96ff ;
                            font-style: normal;
                        }
                        .-year{
                            padding-left:20px;
                        }
                    }
                    .-title{
                        font-size: 30px;
                        line-height: 48px;
                        &:hover{
                        text-decoration: underline;
                        cursor: pointer;
                    }
                    }
                    .-info{
                        padding-top:20px;
                        font-size: 18px;
                        line-height: 32px;
                    }
                    .-more{
                        position: absolute;
                        bottom:0;
                        left:0;
                        height:100px;
                        color:#82aaf0;
                        stroke:#82aaf0;
                        line-height: 100px;
                        a{
                            cursor: pointer;
                            &:hover{
                                color:#5a96ff;
                                svg{
                                    stroke:rgb(90, 150, 255);
                                }
                            }
                        }
                    }
                }
            }
            .-news-list-content{
                width:1200px;
                margin:0 auto;
                .-news-list-item{
                    width:100%;
                    color:#464343;
                    padding:40px 0;
                    display: flex;
                    &+.-news-list-item{
                        border-top:1px dashed #464343;
                    }
                    .-time{
                        flex:0 0 auto;
                        width:174px;
                        height: 174px;
                        background-color: #5a96ff;
                        border-radius: 10px;
                        color:#fff;
                        margin-right:40px;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        span{
                            line-height: 66px;
                            font-size: 54px;
                        }
                        > div{
                            line-height: 40px;
                            font-size: 18px;
                            border-top:2px solid #fff;
                        }
                    }
                    .-p{
                        &:hover{
                            cursor: pointer;
                            .-title{
                                text-decoration: underline;
                            }
                        }
                        .-title{
                            font-size: 26px;
                            line-height: 48px;
                        }
                        .-info{
                            padding-top:12px;
                            font-size: 18px;
                            line-height: 32px;
                        }
                    }
                }
                .-pager{
                    padding:30px 0;
                    display: flex;
                    justify-content: center;
                    .Page{
                        width:40px;
                        height:40px;
                        font-size: 16px;
                    }
                    svg{
                        transform: scale(1.5);
                    }
                }
            }
        }
    }
</style>