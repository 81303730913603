
import axios , {AxiosInstance,AxiosResponse} from 'axios';

import qs from 'qs';

export class baseDaoUtils{

    protected axiosInstance!:AxiosInstance;

    constructor(){
        this.initAxiosInstance();
        this.setInterceptor();
    }

    protected initAxiosInstance():void{
        this.axiosInstance = axios.create({
            withCredentials:true,
            headers:{
                'Cache-Control': 'no-cache',
                // 'Content-Type': "application/json;charset=utf-8"
                'Content-Type': "application/x-www-form-urlencoded;charset=utf-8"
            }
        })
    }
    protected setInterceptor():void{
        this.axiosInstance.interceptors.response.use((response:AxiosResponse)=>{
            if(response.data){
                return this.handleResponse(response);
            }else{
                return Promise.reject(response.data.message || response.data.msg || '服务器开了小差！');
            }
        },(error:string|undefined)=>{
            this.handleError(error || 'jwError:unknow error');
            return Promise.reject(error);
        });
    }
    protected handleResponse(response:AxiosResponse):object{
        return response.data;
    }
    protected handleError(errorMsg:string):void{
        console.error(errorMsg);
    }
    public setServerAddress(serverAddress:string):void{     
    
        this.axiosInstance.defaults.baseURL = serverAddress || (window as any).APIADDRESS
    }
    public setToken(token:string):void{
        this.axiosInstance.defaults.headers.common['token'] = token;
    }

    /* get请求方法 */
    public get(url:string, params:object={}) {
        return new Promise((resolve, reject) => {
            this.axiosInstance
                .get(url, {
                    params: params
                })
                .then(function(response:AxiosResponse) {
                    resolve(response)
                })
                .catch((error:string) => {
                    reject(error)
                })
        })
    }

    public post(url:string,params:object = {}):Promise<any>{
        return new Promise((resolve,reject)=>{
            this.axiosInstance
                .post(url,qs.stringify(params))
                .then(function(response:AxiosResponse){
                    resolve(response);
                })
                .catch((error:string)=>{
                    reject(error)
                });
        })
    }
    /** json 提交方式 */
    public postByJson(url:string,data:object={}):Promise<any>{
        return new Promise((resolve,reject)=>{
            this.axiosInstance({
                method:'post',
                url,
                data,
                headers:{
                    'Content-Type': 'application/json'
                }
            }).then(function(response:AxiosResponse){
                resolve(response);
            }).catch((error:string)=>{
                reject(error);
            })
        })
    }
    /* post提交附件方法 */
    public postFile(url:string, params:object = {}):Promise<any> {
        return new Promise((resolve, reject) => {
            this.axiosInstance
                .post(url, params)
                .then(function(response:AxiosResponse){
                    resolve(response)
                })
                .catch((error:string) => {
                    reject(error)
                })
        })
    }

    /* 导出方法 */
    public exportData(url:string, params:object = {}):void {
        const _search = []
        for (const key in params) {
        if ((params as any)[key]) {
            _search.push(`${key}=${(params as any)[key]}`)
        }
        }
        window.open(`${(window as any).APIADDRESS}${url}?${_search.join('&')}`)
    }


    protected _download(data:any,downName:string){ //二进制下载
        if(!data){
            return;
        }
        const url:string = window.URL.createObjectURL(new Blob([data]));
        if(!document.querySelector('#downloadLink')){
            const link = document.createElement('a');
            link.style.display = 'none';
            link.id = 'downloadLink';
            document.body.appendChild(link);
        }
        const _linkButton:HTMLAnchorElement = document.querySelector('#downloadLink') as HTMLAnchorElement;
        _linkButton.href = url;
        _linkButton.setAttribute('download', downName);
        _linkButton.click();
    }

}

export default baseDaoUtils;