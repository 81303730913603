<template>
     <div class="-contact-container">
        <div class="-top-banner"></div>
        <div class="-map">
            <iframe src="https://uri.amap.com/marker?position=119.273777,26.078087&name=福信富通科技股份有限公司" 
                frameborder="0" width="1200px" height="600px"></iframe>
        </div>
    </div>
</template>

<script lang="ts" setup>
    import { useRoute,useRouter } from 'vue-router';
    import { computed,ref,Ref } from 'vue';
    import {newsMenu} from '../../dic/menuInfo';


    const route = useRoute();
    const activeName = computed(()=>{
        return route.params.type;
    });
    const router = useRouter();
    const linkTo = (link:string)=>{
        router.push(`${newsMenu.parentLink}/${link}`);
    }
    

</script>
<style lang="scss">

    $mainColor:#e7871d;        
    .-contact-container{
        .-top-banner{
            width:100%;
            height:434px;
            background-position: center center;
            background-image:url('~@/images/contact_banner.png');
        }
        .-map{
            width:1200px;
            margin:0 auto;
            padding:100px 0;
        }
    }
</style>