export const intellectualInfo = [
    {
        pic:require('@/images/ip/1.jpg'),
        title: `实现TMC移动导航终端的增量信息更新的办法`
    },
    { 
        pic:require('@/images/ip/2.jpg'),
        title: `一种彩色图像边界提取方法`
    },
    {
        pic:require('@/images/ip/3.jpg'),
        title:'一种视频序列彩色图像拼接方法'
    },
    {
        pic:require('@/images/ip/4.jpg'),
        title:'基于网络和传感器的车载智能应急启动电源防盗终端'
    },
    {
        pic:require('@/images/ip/5.jpg'),
        title: `一种基于重力感应器和GPS的停车监控方法`
    },
    {
        pic:require('@/images/ip/6.jpg'),
        title:'一种基于车载网络的智能家居监视系统'
    },
    {
        pic:require('@/images/ip/7.jpg'),
        title:'一种车辆安全系统'
    },
    {
        pic:require('@/images/ip/8.jpg'),
        title:'一种疲劳驾驶检测方法'
    },
    {
        pic:require('@/images/ip/9.png'),
        title:'一种基于自动化大数据的主动安全监控平台测试方法'
    },
    {
        pic:require('@/images/ip/10.png'), 
        title:'一种北斗智能定位鞋及其脚步运动形态的识别方法'
    },
    {
        pic:require('@/images/ip/11.png'),
        title:'一种可组合的微服务开发框架的部署方法、装置及设备'
    },
    {
        pic:require('@/images/ip/12.png'),
        title:'一种基于北斗大数据的通行速度引导方法'
    },
    {
        pic:require('@/images/ip/101.png'),
        title:'一种网约车管理系统储存装置'
    },
    {
        pic:require('@/images/ip/102.png'),
        title:'一种基于北斗的定位装置及船舶定位装置'
    },
    {
        pic:require('@/images/ip/103.jpg'),
        title:'一种车载智能应急启动电源防盗电路'
    },
    {
        pic:require('@/images/ip/104.jpg'),
        title:'一种车载智能应急电源防盗结构'
    },
    {
        pic:require('@/images/ip/105.jpg'),
        title:'一种嵌入型智能OBU行车记录装置'
    },
    {
        pic:require('@/images/ip/106.jpg'),
        title:'一种可体验的驾驶舱'
    },
    {
        pic:require('@/images/ip/107.jpg'),
        title:'一种校园电子学生证'
    },
    {
        pic:require('@/images/ip/108.jpg'),
        title:'智能手环'
    },
    {
        pic:require('@/images/ip/109.jpg'),
        title:'一种车载安全智能防控系统'
    },
    {
        pic:require('@/images/ip/110.jpg'),
        title:'智能交通三脚架'
    },
    {
        pic:require('@/images/ip/111.jpg'),
        title:'一种智能路标'
    },
    {
        pic:require('@/images/ip/112.jpg'),
        title:'一种用于定位鞋的发电机构'
    },

    {
        pic:require('@/images/ip/201.jpg'),
        title:'嵌入型智能OBU行车记录仪'
    },
    {
        pic:require('@/images/ip/202.jpg'),
        title:'手机的图形用户界面（简化版）'
    },
    {
        pic:require('@/images/ip/203.jpg'),
        title:'手机的图形用户界面（标准与升级版）'
    },
    {
        pic:require('@/images/ip/204.jpg'),
        title:'手机的图形用户界面（轨迹回放）'
    },
    {
        pic:require('@/images/ip/202.jpg'),
        title:'手机的图形用户界面'
    },
    {
        pic:require('@/images/ip/205.jpg'),
        title:'车载ETC北斗定位终端'
    },
    {
        pic:require('@/images/ip/206.jpg'),
        title:'北斗高精度车载定位终端'
    },
    {
        pic:require('@/images/ip/203.jpg'),
        title:'智能学生证（北斗）'
    },
];