
export const scrollTop = (y:number)=>{
    const startY = window.scrollY;
    const startX = window.scrollX;
    const distance = y - startY;

    const duration = Math.abs(distance / 2);

    const startTime = performance.now();
    const animation = (currentTime:number)=>{
        const timeElapsed = currentTime - startTime;
        const run = ease(timeElapsed, startY, distance, duration);
        window.scrollTo(startX, run);
        if (timeElapsed < duration) {
          requestAnimationFrame(animation);
        }
    }

    function ease(t:number, b:number, c:number, d:number) {
        t /= d / 2;
        if (t < 1) return (c / 2) * t * t + b;
        t--;
        return (-c / 2) * (t * (t - 2) - 1) + b;
    }
    
      requestAnimationFrame(animation);
}

