<template>
     <div class="info-show-container">
        <div class="info-show-wrapper">
            <div class="swiper-slide">
                <div class="-bg-container -info-1" :style="bg1Transform"></div>
                <div class="-panel">
                    <wheel-icon></wheel-icon>
                </div>
                <div class="-info-content -info-left">
                    <h3 :style="bg1HTransform">北斗 + 数字交通</h3>
                    <h6 :style="bg1PTransform">交通全链条全要素实现动态监管</h6>
                    <h6 :style="bg1PTransform">构建安全隐患 “发现-处置-跟踪-评估” 体系</h6>
                    <div class="-btn-panel"  :style="bg1BTransform">
                        <a class="-swiper-btn" @click="toPage('traffic')">
                            了解详情
                            <i :style="{backgroundImage:`url(${arrowRight})`}"></i>
                        </a>
                    </div>
                </div>
            </div>
            <div class="swiper-slide">
                <div class="-bg-container -info-2" :style="bg2Transform"></div>
                <div class="-panel">
                    <wheel-icon></wheel-icon>
                </div>
                <div class="-info-content -info-right">
                    <h3 :style="bg2HTransform">北斗 + 数字海洋</h3>
                    <h6  :style="bg2PTransform">海洋经济与数字经济深度融合与协同发展</h6>
                    <h6  :style="bg2PTransform">聚势汇能推动海洋经济高质量发展</h6>
                    <div class="-btn-panel"  :style="bg2BTransform">
                        <a class="-swiper-btn" @click="toPage('ocean')">
                            了解详情
                            <i :style="{backgroundImage:`url(${arrowRight})`}"></i>
                        </a>
                    </div>
                </div>
            </div>
            <div class="swiper-slide">
                <div class="-bg-container -info-3"  :style="bg3Transform"></div>
                <div class="-panel">
                    <wheel-icon></wheel-icon>
                </div>
                <div class="-info-content -info-left">
                    <h3 :style="bg3HTransform">北斗 + 数字低空</h3>
                    <h6 :style="bg3PTransform">构建北斗时空数智低空底座</h6>
                    <h6 :style="bg3PTransform">探索 “空天地网” 立体化管理模式</h6>
                    <div class="-btn-panel"  :style="bg3BTransform">
                        <a class="-swiper-btn" @click="toPage('altitude')">
                            了解详情
                            <i :style="{backgroundImage:`url(${arrowRight})`}"></i>
                        </a>
                    </div>
                </div>
            </div>
            <div class="swiper-slide">
                <div class="-bg-container -info-4"  :style="bg4Transform"></div>
                <div class="-panel">
                    <wheel-icon></wheel-icon>
                </div>
                <div class="-info-content -info-right">
                    <h3 :style="bg4HTransform">北斗 + 数字能源</h3>
                    <h6 :style="bg4PTransform">降低能源浪费 推动可持续能源的发展</h6>
                    <h6 :style="bg4PTransform">实现对能源消耗和供应的智能监控和优化控制</h6>
                    <div class="-btn-panel" :style="bg4BTransform">
                        <a class="-swiper-btn" @click="toPage('energy')">
                            了解详情
                            <i :style="{backgroundImage:`url(${arrowRight})`}"></i>
                        </a>
                    </div>
                </div>
            </div>
            <div class="swiper-slide">
                <div class="-bg-container -info-5"  :style="bg5Transform"></div>
                <div class="-panel">
                    <wheel-icon></wheel-icon>
                </div>
                <div class="-info-content -info-left">
                    <h3 :style="bg5HTransform">北斗 + 数字城市</h3>
                    <h6 :style="bg5PTransform">打造北斗智慧城市时空感知网络</h6>
                    <h6 :style="bg5PTransform">完善数字治理 美好生活触手可及</h6>
                    <div class="-btn-panel" :style="bg5BTransform">
                        <a class="-swiper-btn" @click="toPage('city')">
                            了解详情
                            <i :style="{backgroundImage:`url(${arrowRight})`}"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>

    import wheelIcon from './../../common/wheelIcon.vue';

    import arrowRight from '@/images/arrow-r.png';
    import {scrollTop as scrollTopFun} from './../../dic/utils';

    import {debounce} from 'lodash';


    import {onMounted,watch,computed,Ref,ref,onUnmounted} from 'vue';
    import { useRoute,useRouter } from 'vue-router';
    const router = useRouter();

    const toPage=(type:string)=>{
        router.push(`solution/${type}`);
    }

    const singleHeight = Math.max(window.innerHeight,800);

    let scrollTop:Ref<number> = ref(0);

    const eventA = ()=>{
        scrollTop.value = window.scrollY;
    };

    // const bg1Transform:Ref<string> = ref('transform:scale(1.1);')
    const bg1Transform = computed(()=>{
        return `transform:scale(1.2) translateY(${Math.floor(((scrollTop.value/singleHeight * 0.15) - 0.1)*singleHeight)}px);`
    });

    const bg1HTransform = computed(()=>{
        return `transform:translateY(${Math.max(0,(((1 - scrollTop.value/singleHeight) * 570)))}px);`
    })
    const bg1PTransform = computed(()=>{
        return `transform:translateY(${Math.max(0,(((1 - (scrollTop.value- 100)/singleHeight) * 420)))}px);`
    })    
    const bg1BTransform = computed(()=>{
        return `transform:translateY(${Math.max(0,(((1 - (scrollTop.value- 200)/singleHeight) * 400)))}px);`
    })

    const bg2Transform = computed(()=>{
        return `transform:scale(1.2) translateY(${Math.floor((((scrollTop.value - singleHeight)/singleHeight * 0.15) - 0.1)*singleHeight)}px);`
    });
    const bg2HTransform = computed(()=>{
        return `transform:translateY(${Math.max(0,(((1 - (scrollTop.value - singleHeight)/singleHeight) * 570)))}px);`
    })
    const bg2PTransform = computed(()=>{
        return `transform:translateY(${Math.max(0,(((1 - ((scrollTop.value - singleHeight)- 100)/singleHeight) * 420)))}px);`
    })    
    const bg2BTransform = computed(()=>{
        return `transform:translateY(${Math.max(0,(((1 - ((scrollTop.value - singleHeight) - 210)/singleHeight) * 300)))}px);`
    })

    
    const bg3Transform = computed(()=>{
        return `transform:scale(1.2) translateY(${Math.floor((((scrollTop.value - singleHeight*2)/singleHeight * 0.15) - 0.1)*singleHeight)}px);`
    });
    const bg3HTransform = computed(()=>{
        return `transform:translateY(${Math.max(0,(((1 - (scrollTop.value - singleHeight*2)/singleHeight) * 570)))}px);`
    })
    const bg3PTransform = computed(()=>{
        return `transform:translateY(${Math.max(0,(((1 - ((scrollTop.value - singleHeight*2)- 100)/singleHeight) * 420)))}px);`
    })    
    const bg3BTransform = computed(()=>{
        return `transform:translateY(${Math.max(0,(((1 - ((scrollTop.value - singleHeight*2) - 220)/singleHeight) * 300)))}px);`
    })

    
    const bg4Transform = computed(()=>{
        return `transform:scale(1.2) translateY(${Math.floor((((scrollTop.value - singleHeight*3)/singleHeight * 0.15) - 0.1)*singleHeight)}px);`
    });
    const bg4HTransform = computed(()=>{
        return `transform:translateY(${Math.max(0,(((1 - (scrollTop.value - singleHeight*3)/singleHeight) * 570)))}px);`
    })
    const bg4PTransform = computed(()=>{
        return `transform:translateY(${Math.max(0,(((1 - ((scrollTop.value - singleHeight*3)- 100)/singleHeight) * 420)))}px);`
    })    
    const bg4BTransform = computed(()=>{
        return `transform:translateY(${Math.max(0,(((1 - ((scrollTop.value - singleHeight*3) - 230)/singleHeight) * 300)))}px);`
    })


    const bg5Transform = computed(()=>{
        return `transform:scale(1.2) translateY(${Math.floor((((scrollTop.value - singleHeight*4)/singleHeight * 0.15) - 0.1)*singleHeight)}px);`
    });
    const bg5HTransform = computed(()=>{
        return `transform:translateY(${Math.max(45,(((1 - (scrollTop.value - singleHeight*4)/singleHeight) * 570)))}px);`
    })
    const bg5PTransform = computed(()=>{
        return `transform:translateY(${Math.max(45,(((1 - ((scrollTop.value - singleHeight*4)- 100)/singleHeight) * 420)))}px);`
    })    
    const bg5BTransform = computed(()=>{
        return `transform:translateY(${Math.max(78,(((1 - ((scrollTop.value - singleHeight*4) - 240)/singleHeight) * 300)))}px);`
    })

    const scrollEvent = debounce((event:WheelEvent)=>{
            let current = Math.floor((window.scrollY+1)/singleHeight); 
        if(event.deltaY > 0){
            scrollTopFun((current + 1)*singleHeight);
        }else if(event.deltaY < 0){
            scrollTopFun((current)*singleHeight);  
        }
    },100);
    onMounted(()=>{
        window.addEventListener('scroll',eventA);
        window.addEventListener('mousewheel',scrollEvent); 
    })
    onUnmounted(()=>{
        window.removeEventListener('scroll',eventA);
        window.removeEventListener('mousewheel',scrollEvent);  
    })
    
    
</script>
<style  lang="scss">
    .info-show-container{
        background-color: #212121;; 
        width:100%;
        min-width:1200px;
        position: relative;
        .-bg-container{
            position: absolute;
            top:0;
            left:0;
            height:100%;
            width:100%;
            background-repeat: no-repeat;
            background-size: cover;
            transform: scale(1.1);
            &.-info-1{
                background-image:url('~@/images/info_1.png');
            }  
            &.-info-2{
                background-image:url('~@/images/info_2.png');
            } 
            &.-info-3{
                background-image:url('~@/images/info_3.png');
            } 
            &.-info-4{
                background-image:url('~@/images/info_4.png');
            }
            &.-info-5{
                background-image:url('~@/images/info_5.png');
            }
        }
        .-panel{
            position:absolute;
            bottom:30px;
            height:30px;
            width:180px;
            align-items: center;
            justify-content: center;
            display: flex;
            left:50%;
            margin-left:-90px;
            z-index: 9;
        }
        .info-show-wrapper{
            // width:100%;
            // height:800px;
            // width: 100vw; 
            // height:100vh;
            .swiper-slide{                 
                width:100%;
                height:800px;
                width: 100vw;
                height:100vh;
                position:relative;
                color:#fff;
                text-align: center;
                overflow: hidden;
                .-bg{
                    width:100%;
                    height:100%;
                    top:0;
                    left:0;
                    position: absolute;
                }
                .-info-content{
                    width:1200px;
                    margin:0 auto;
                    height:100%;
                    position: relative;
                    padding-top: 320px;
                    h3{
                        font-size:80px;
                        line-height: 140px;
                        transition: all 0.1s linear;
                    }
                    h6{
                        font-size:20px;
                        line-height: 40px;
                        font-weight: normal;
                        transition: all 0.1s linear;
                    }
                }
            }
            .-btn-panel{
                // padding-top:70px;
                display: flex;
                transition: all 0.1s linear;
                .-swiper-btn{
                    cursor: pointer;
                    width:180px;
                    height:50px;
                    color:#fff;
                    background-color: #2d5cba;
                    border-radius: 3px;
                    display: block;
                    line-height: 50px;
                    font-size: 18px;
                    display: flex;
                    justify-content: space-between;
                    padding:0 20px;
                    box-sizing: border-box;
                    align-items: center;
                    i{
                        display: block;
                        width:11px;
                        height:13px;
                    }
                }
            }
        }
        .-info-left{
            text-align: left;
            .-btn-panel{
                justify-content: flex-start;
            }
        }
        .-info-right{
            text-align: right;
            .-btn-panel{
                justify-content: flex-end;
            }
        }
    }
</style>