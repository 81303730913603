<template>
     <div class="swiper">
        <div class="swiper-wrapper">
            <div class="swiper-slide -banner-1"></div>
            <div class="swiper-slide -banner-2"></div>
        </div>
        <div class="-panel">
            <a class="-btn -banner-prev"><i :style="{backgroundImage:`url(${arrowLeft})`}"></i></a>
            <wheel-icon></wheel-icon>
            <a class="-btn -banner-next"><i :style="{backgroundImage:`url(${arrowRight})`}"></i></a>
        </div>
    </div>
</template>

<script lang="ts" setup>
    import wheelIcon from './../../common/wheelIcon.vue';

    import arrowLeft from '@/images/arrow-l.png';
    import arrowRight from '@/images/arrow-r.png';
    import Swiper from 'swiper';

    import {Navigation,Autoplay} from 'swiper/modules';

    import 'swiper/css';
    import 'swiper/css/bundle';


    import {onMounted} from 'vue';
    // Swiper.use(Navigation);

    onMounted(()=>{
        const swiper = new Swiper('.swiper',{
            loop:true,
            // centeredSlides:true,
            modules:[Navigation,Autoplay],
            autoplay:{
                delay:5000,
                disableOnInteraction:false
            },
            navigation:{
                nextEl:'.-banner-next',
                prevEl:'.-banner-prev'
            }
        });
    })
    
    
</script>
<style  lang="scss">
    .swiper{
        background-color: #212121;;
        width:100%;
        height:800px;
        width: 100vw;
        height:100vh;
        min-width:1200px;
        position: relative;
        .swiper-wrapper{
            .swiper-slide{
                color:#fff;
                text-align: center;
                background-position: center center;
                &.-banner-1{
                    background-image:url('~@/images/banner1.png');
                }
                &.-banner-2{
                    background-image:url('~@/images/banner2.jpg');
                }
            }
        }
        .-panel{
            position:absolute;
            bottom:30px;
            height:30px;
            width:180px;
            align-items: center;
            justify-content: center;
            display: flex;
            left:50%;
            margin-left:-90px;
            z-index: 9;
            .-btn{
                width:30px;
                height:100%;
                opacity: 0.7;
                display: flex;
                align-items: center;
                justify-content: center;
                margin:0 20px;
                cursor: pointer;
                &:hover{
                    opacity: 1;
                }
                > i{
                    width:11px;
                    height:13px;
                    display: block;
                }
            }
        }
    }
</style>