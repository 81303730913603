
import {baseDaoUtils} from '@/utils/base/baseDaoUtils';
import { zzTipError } from '@/utils/noticeUtils';

export class baseDao extends baseDaoUtils{
    constructor(){
        super();
    }   
    protected handleError(errorMsg:string):void{
        try {
            zzTipError(errorMsg)
        } catch (e) {
            console.error(e)
        }      
    }
}

export default baseDao;