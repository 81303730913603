<template>
     <div class="-culture-container">
        <div class="-top-banner"></div>
        <div class="-culture-container">
            <div class="-culture-content">
                <div class="-culture-item">
                    <h5>愿景</h5>
                    <div class="-bg -b1">
                        <span class="-ip">为时空定位 为梦想导航</span>
                        <span>构建数字中国北斗时空大数据底座</span>
                    </div>
                </div>
                <div class="-culture-item">
                    <h5>使命</h5>
                    <div class="-bg -b2">
                        <span class="-ip">数字中国建设北斗创新应用标兵</span>
                        <span>数字中国建设 “一带一路” 先行者</span>
                    </div>
                </div>
                <div class="-cluture-list">
                    <div v-for="(item,i) in clutureList" :key="i">
                        <div class="-l" v-text="item.title"></div>
                        <div class="-r" v-text="item.sub"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
    console.log('test');
    const clutureList = [
        {
            title:'核心价值观',
            sub:'科技驱动、变革创新、务实奋进、合作共赢'
        },
        {
            title:'企业精神',
            sub:'诚实守信、求真务实、创新进取、追求卓越'
        },
        {
            title:'经营理念',
            sub:'客户至上、快速高效、开放合作、创新精进'
        },
        {
            title:'管理理念',
            sub:'以人为本、制度为纲、效益为先、共同发展'
        }
    ]
</script>
<style lang="scss">

    $mainColor:#e7871d;        
    .-culture-container{
        .-top-banner{
            width:100%;
            height:590px;
            background-position: center center;
            background-image:url('~@/images/cluture/banner.png');
        }
        .-culture-container{
            width:100%;
            .-culture-content{
                width:1200px;
                margin:0 auto;
                .-culture-item{
                    margin:50px 0;
                    h5{
                        font-size: 30px;
                        line-height: 60px;
                        line-height: 110x;
                        color:#325ebf;
                        position: relative;
                        text-align: center;
                        text-align: left;
                        font-weight: normal;
                    }
                    .-bg{
                        width:100%;
                        height:420px;
                        background-repeat: no-repeat;
                        background-position: center center;
                        box-sizing: border-box;
                        padding-top:100px;
                        &.-b1{
                            background-image:url('~@/images/cluture/clu-1.png');
                        }
                        &.-b2{
                            background-image:url('~@/images/cluture/clu-2.png');
                        }
                        > span{
                            color:#fff;
                            text-align: center;
                            font-size: 30px;
                            line-height: 50px;
                            display: block;
                            &.-ip{
                                font-weight: bold;
                            }
                        }
                    }
                }
                .-cluture-list{
                    padding: 40px 0 80px 0;
                    > div{
                        background-color: #5a96ff;
                        display: flex;
                        width:100%;
                        margin:30px 0;
                        height:58px;
                        color:#fff;
                        align-items: center;
                        font-size: 24px;
                        .-l{
                            width:200px;
                            height:100%;
                            text-align: center;
                            background-color: #325ebf;
                            font-size: 30px;
                            line-height: 58px;
                        }
                        .-r{
                            padding-left:60px;
                        }
                    }
                }
            }
        }
    }
</style>