<template>
     <div class="container">
        <common-header></common-header>
        <router-view></router-view>
        <!-- <index-page></index-page> -->
        <common-footer></common-footer>
    </div>
</template>

<script lang="ts" setup>

    import { computed } from 'vue';
    import {useRoute, useRouter} from 'vue-router';

    import commonHeader from './common/commonHeader.vue';
    import commonFooter from './common/commonFooter.vue';




    const route = useRoute();

    
</script>
<style lang="scss">
    html,body{
        margin:0;
        padding:0;
    }
    h1,h2,h3,h4,h5,h6{
        margin:0;
    }
    .container{
        min-width: 1200px;
    }
</style>