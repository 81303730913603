

// eslint-disable-next-line
export const zzConfirm = (message:string, submitCb?:any, cancelCb?:any, options:object={}):void => {
  console.log('1')
}
export const zzConfirmAsync = (message:string, options:object={})=>{
    console.log('1')
}


// eslint-disable-next-line
export const zzAlert = (message:string, cb?:any):void => {
    console.log('1')
}

export const zzTip = (message:string):void => {
    console.log('1')
}
export const zzTipError = (message:string):void => {
    console.log('1')
}


  