import {createRouter,createWebHashHistory,createWebHistory, RouteRecordRaw} from 'vue-router';


import indexPage from './../views/pages/index/indexPage.vue';
import aboutPage from './../views/pages/about/aboutPage.vue';
import solutionPage from './../views/pages/solution/solutionPage.vue';
import sciencePage from './../views/pages/science/sciencePage.vue';
import newsPage from './../views/pages/news/newsPage.vue';

import newsDetail from './../views/pages/news/newsDetail.vue';

import culturePage from './../views/pages/culture/culturePage.vue';
import hrPage from './../views/pages/hr/hrPage.vue';


import contactPage from './../views/pages/contactus/contactPage.vue';

const routes:Array<RouteRecordRaw> = [
    {
        path:'/index',
        name:'index',
        component:indexPage
    },
    {
        path:'/aboutus/:type',
        name:'aboutus',
        component:aboutPage
    },
    {
        path:'/solution/:type',
        name:'solution',
        component:solutionPage
    },
    {
        path:'/science',
        name:'science',
        component:sciencePage
    },
    {
        path:'/news/:type',
        name:'news',
        component:newsPage
    },
    {
        path:'/newsdetail/:type',
        name:'newsdetail',
        component:newsDetail
    },
    {
        path:'/culture',
        name:'culture',
        component:culturePage
    },
    {
        path:'/hr',
        name:'hr',
        component:hrPage
    },
    {
        path:'/contactus',
        name:'contactus',
        component:contactPage
    },
    {
        path:'/:catchAll(.*)',
        redirect: "/index"
    }
]

export const router = createRouter({
    // history: createWebHistory(),
    history: createWebHashHistory(),
    routes,
  });

const originalPush = router.push;
router.push = function push(location){
    return originalPush.call(this,location).catch((err)=>err)
}

router.beforeEach((to,from,next)=>{
    const meta = to.meta;
    // store.commit('setShowHead',meta.showHead);
    // store.commit('setShowCrumb',meta.setShowCrumb);
    if(document.fullscreenElement){
        document.exitFullscreen();
    }
    next();
});
export default router;